/**************************************************************************
***************** Master Stylesheet for ecommerce************************
***************************************************************************
=========================== Content Index =================================
* 1. General styles
* 2. HOME-1 STYLES
     - Info bar styles
     - Header-1 styles
     - Shopping widget styles
     - Home product section styles
     - Home baners section styles
     - Home vertical-product section styles
     - Home footer section styles
* 3. HOME-2 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-3 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-4 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 3. Menu-1 styles
* 4. Menu-2 styles
* 5. Products left sidebar page styles
* 6. Popular product page styles
* 7. My account page styles
* 11. Price slider styles
* 12. Product dialog styles
* 13. Popular products styles

***************************************************************************
**************************************************************************/
/***************************************/
/* Icon Pulse Shrink */
@-webkit-keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.hvr-icon-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-pulse-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-pulse-shrink:hover .hvr-icon, .hvr-icon-pulse-shrink:focus .hvr-icon, .hvr-icon-pulse-shrink:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-shrink;
  animation-name: hvr-icon-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
@-webkit-keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}
.color-options{
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 350px;
  right: -360px;
  background-color: white;
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
  transition: all 0.5s ease;
  .options-icon{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f7f7f7;
    width: 40px;
    height: 40px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    transition: all 0.5s ease;
    z-index: 2;
    .mat-icon {
      -webkit-animation: rotation 2s infinite linear;
      color: #333;
    }
  }
  .mat-card{
      position: absolute;
      padding: 14px;
      width: 100%;
      height: 100%;
  }
  &.show{
      right: -2px;
      -webkit-transition: max-height 0.25s ease-in;
      -moz-transition: max-height 0.25s ease-in;
      transition: all .5s ease;
  }
  .card-title-text {
    padding: 1rem 1rem;
    background: #f5f5f5;
    h4 {
      color: #333 !important;
    }
  }
  .configuration-content {
    padding: 1rem 1rem;

    .setting-contant {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      padding-bottom: 1rem !important;
      margin-bottom: 1rem !important;
      .color-box {
        li {
          display: flex;
          align-items: center;
          input {
            border: none;
            box-shadow: none;
            width: 34px;
            height: 34px;
            background-color: transparent;
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
      .products-preview-box-wrapper {
   .item {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px;
    .products1 {

      height: 110px;
      width: 100%;
      display: inline-flex;
      background-size: cover;
      transition: all 2s ease-out 0s;
      box-shadow: -1px 2px 5px 1px #ececec;
      img {
        width: 100%;
      }
    }
    .products2 {
      height: 110px;
      width: 100%;
      display: inline-flex;
      background-size: cover;
      transition: all 2s ease-out 0s;
      box-shadow: -1px 2px 5px 1px #ececec;
      img {
        width: 100%;
      }
    }
    .products3 {
      height: 110px;
      width: 100%;
      display: inline-flex;
      background-size: cover;
      transition: all 2s ease-out 0s;
      box-shadow: -1px 2px 5px 1px #ececec;
      img {
        width: 100%;
      }
    }
     .demo-text {
       text-align: center;
       background: transparent;

       .btn-preview {
        background: transparent;
        margin-top: 14px;
        a {
          border: 1px solid #ddd;
          background-color: white;
          width: 90px;
          color: var(--theme-default);
          padding: 4px 12px;
          border-radius: 30px;
         }
       }

     }
     &:hover {
      .products1, .products2, .products2 {
        background-position: center 100% !important;
      }

     }
   }
      }
      .theme-layout-version {
cursor: pointer;
      }
    }

    h6 {
      text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 1rem;
    }
  }
}


/***************************************
* 1. General styles
****************************************/


html, body { height: 100%; }
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  position: relative;
  font-size: 1rem;
 color: $font-color !important;
}

h1 {
  font-size: 60px;
  color: $theme-main-dark;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 36px;
  color: $theme-main-dark;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 28px;
  text-align: left;
  color: $theme-main-dark;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: $theme-main-dark;
  line-height: 24px;
  letter-spacing: 0.05em;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: $theme-main-dark;
  line-height: 24px;
}
* {
  margin: 0;
  padding: 0;
}
.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.mat-slider-track-fill {
  background-color: $theme-main;
}
.mat-primary .mat-slider-thumb {
  background-color: $theme-main;
}
.mat-raised-button.mat-warn {
  background-color: $theme-main-red;
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;

}
// .overlay {
//   background-color: rgba(255, 193, 17, 0.8) !important;
//   position: fixed;
// }
.mat-form-field.mat-focused .mat-form-field-label{color:var(--theme-default) !important;}
.mat-form-field.mat-focused .mat-form-field-ripple{background-color:var(--theme-default) !important;}
.mat-expansion-indicator::after, .mat-expansion-panel-header-description {
  color:$grey4;
}
.sophia-app .mat-drawer-container {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
  background-color: rgb(237, 236, 236,1);
}
.mat-menu-panel {
  min-width: 100% !important;
  max-width: 100% !important;

}
.mat-mini-fab.mat-accent {
  background-color: $theme-main;
}
a {
  text-decoration: none;
}
.text-center {
  text-align: center !important;
}
.mat-snack-bar-container.error {
  background: $theme-main-red !important;
}
.mat-snack-bar-container.info {
  background: rgba(0,0,0,.6) !important;
  color:#fff;
}
.mat-snack-bar-container.warning {
  background: rgba(255,204,0,.6) !important;
  color:#000;
}
.mat-snack-bar-container.success {
  background: var(--theme-default)!important;
  color:#fff;
}
.mat-snack-bar-container button {
  width: 10px !important;
  color:#fff !important;
  background-color: transparent !important;
  padding: 0px !important;
}
.mat-drawer-backdrop {
  background-color: rgba(0,0,0,.6);
}
.mat-drawer-container {
  color: $font-color;
}
.mat-radio-label-content {
  color: $grey7;
  font-weight: 400;
  }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
    background-color: $theme-main-red;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background: var(--theme-default) !important;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--theme-default) !important;
}
.mat-raised-button.mat-primary {
  background-color: var(--theme-default);
  color: $white;
  border-radius: 0 !important;
}
.mat-input-element {
  caret-color: $theme-main !important;;
}

.mat-tab-group.mat-primary {
  .mat-tab-label {
    opacity: .7;
  }
    .mat-ink-bar {
      background-color: var(--theme-default);
    }


}
.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px !important;
}
.divider {
  border-top: 1px solid $grey3;
}
.bg {
  background: $background;
}
.ml-10 {
  margin-left: 10px;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.w-50 {
  width: 50%;
}
.mr-10 {
  margin-right: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mr-7 {
  margin-right: 7px !important;
}
.ml-7 {
  margin-left: 7px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.no-pdn {
  padding: 0 !important;
}
.pt-20 {
padding-top: 20px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pt-40 {
  padding-top: 40px !important;
  }
  .pt-60 {
    padding-top: 60px !important;
    }
    .devider {
      padding-top: 15px;
    padding-bottom: 20px;
    border-top: 1px dashed $grey;
    }
    .w-100 {
width: 100% !important;
    }
.text-muted {
  color: $grey !important;
}
.text-right {
  text-align: right;
}
.sec-padding {
  padding: 60px 0;
}
@media (max-width: 480px) {
  .sec-padding {
    padding: 20px;
  }
}

.btn-project {
  padding: 5px 32px !important;
  box-shadow: none !important;
  border-radius: 0!important;
  }



/******** Product-1 styles *********/
  .product-item-1 {
    position: unset;
    height: auto;
    transition: .4s;
    padding: 26px 5px 5px 5px;
    .mat-card:not([class*=mat-elevation-z]) {

      -webkit-box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
      -moz-box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
      box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
      padding: 0;
      .product-img {
        background: $baners-background;
        img {
          max-width: 180px;
          margin-bottom: 12px;
          display: block;
            margin-left: auto;
            margin-right: auto;
        }
      }
      .product-info-wrapper {
        padding: 15px;
        .icons {
          position: absolute;
          top: 30px;
          right: 10px;
          opacity: 0;
          transition: all 0.5s ease;
          a {
            color: $font-color;
            cursor: pointer;
            transition: 0.5s ease;
            margin-bottom: 5px;
            &:hover {
              color: var(--theme-default) !important;
            }
          }
        }



        .category{
          margin-bottom: 4px;
      display: block;
      font-weight: 300;
      color: #a2a2a2;
         }
         span {
          color: $white;
          font-size: 14px;

        }
        .price-wrap {
          margin: 10px 0 15px 0;
          .stars {
            color: $theme-main;
            margin: 2px 0 10px 0;
            span {
              color: $grey3;
              font-size: 13px;
              margin-left: 10px;
            }
            mat-icon{
              font-size: 17px;
              color: $theme-main;
                line-height: 17px;
                height: 17px;
                width: 17px;
                vertical-align: middle;
            }
          }
          p{
            font-size: 19px;
           }
           mat-icon{
            color: $white;
           }

        }
        .btn-wrap {
          a {

            cursor: pointer;
            transition: 0.3s;
            width: 100%;
            border-radius: 0;
            text-align: center;
            line-height: 39px;
            color: $white;
            font-size: 14px;
            box-shadow: none !important;
            transition: .4s ease;
            border: 2px solid var(--theme-default) !important;
            mat-icon {

              font-size: 18px;
              vertical-align: middle;
            }
            &:hover {
              border: 2px solid var(--theme-default) !important;

              background: #fff;
              span {
                color:  var(--theme-default) !important;
              }
            }
          }
        }

        .title-wrap {
          h4 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 500;
          }
        }
      }
      &:hover {
        .icons {
         opacity: 1;
        }
        .price-wrap {
          a {
           background: $theme-main;
          }
        }
       }
  }
  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
}
  }
/******** Pack-1 styles *********/
.pack-item-1 {
  position: unset;
  height: auto;
  transition: .4s;
  padding: 0px 0px 0px 12px;
  .mat-card:not([class*=mat-elevation-z]) {
    -webkit-box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
    -moz-box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
    box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
    padding: 0;
    border: 1px solid #bac1c7;
    .product-img {
      background: $baners-background;
      img {
        max-width: 120px;
        // margin-bottom: 12px;
        display: block;
          margin-left: auto;
          margin-right: auto;
      }
    }
    .product-info-wrapper {
      padding: 15px;
      .icons {
        position: absolute;
        top: 30px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s ease;
        a {
          color: $font-color;
          cursor: pointer;
          transition: 0.5s ease;
          margin-bottom: 5px;
          &:hover {
            color: var(--theme-default) !important;
          }
        }
      }
      .category{
        margin-bottom: 4px;
    display: block;
    font-weight: 300;
    color: #a2a2a2;
       }
       span {
        color: $white;
        font-size: 14px;
      }
      .price-wrap {
       p {
        letter-spacing: -.3px;
        color: #3b4e60;
        font-size: 12px;
        font-weight: 400;
       }
      }
      .btn-wrap {
        a {
          // cursor: pointer;
          // transition: 0.3s;
          // text-align: center;
          // line-height: 39px;
          // box-shadow: none !important;
          // transition: .4s ease;
          margin-top: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
          border-radius: 6px;
          width: 100px;
          height: 30px;
          color: #fff;
          font-size: 11px;
          letter-spacing: -.25px;
          font-family: Helvetica Neue;
          // &:hover {
          //   border: 2px solid var(--theme-default) !important;
          //   background: #fff;
          //   span {
          //     color:  var(--theme-default) !important;
          //   }
          // }
        }
      }
      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          // font-weight: 500;
          color: #3b4e60;
          font-size: 14px;
          font-family: Helvetica Neue;
          margin-top: 5px;
          letter-spacing: -.35px;
        }
      }
    }
    &:hover {
      .icons {
       opacity: 1;
      }
     }
}
  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
  }
  .footerButton .mat-button {
    min-width: 42px;
  }
}
/******** customer-type-1 styles *********/
.customer-item-1 {
  position: unset;
  height: auto;
  transition: .4s;
  padding: 0px 0px 0px 12px;
  .mat-card:not([class*=mat-elevation-z]) {
    -webkit-box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
    -moz-box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
    box-shadow: 2px 1px 8px -1px rgba(0,0,0,0.09) !important;
    padding: 0;
    border: 1px solid #bac1c7;
    .product-img {
      background: $baners-background;
      img {
        max-width: 120px;
        // margin-bottom: 12px;
        display: block;
          margin-left: auto;
          margin-right: auto;
      }
    }
    .product-info-wrapper {
      padding: 15px;
      .icons {
        position: absolute;
        top: 30px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s ease;
        a {
          color: $font-color;
          cursor: pointer;
          transition: 0.5s ease;
          margin-bottom: 5px;
          &:hover {
            color: var(--theme-default) !important;
          }
        }
      }
      .category{
        margin-bottom: 4px;
    display: block;
    font-weight: 300;
    color: #a2a2a2;
       }
       span {
        color: $white;
        font-size: 14px;
      }
      .price-wrap {
       p {
        letter-spacing: -.3px;
        color: #3b4e60;
        font-size: 12px;
        font-weight: 400;
       }
      }
      .btn-wrap {
        a {
          // cursor: pointer;
          // transition: 0.3s;
          // text-align: center;
          // line-height: 39px;
          // box-shadow: none !important;
          // transition: .4s ease;
          margin-top: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
          border-radius: 6px;
          width: 100px;
          height: 30px;
          color: #fff;
          font-size: 11px;
          letter-spacing: -.25px;
          font-family: Helvetica Neue;
          // &:hover {
          //   border: 2px solid var(--theme-default) !important;
          //   background: #fff;
          //   span {
          //     color:  var(--theme-default) !important;
          //   }
          // }
        }
      }
      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          // font-weight: 500;
          color: #3b4e60;
          font-size: 14px;
          font-family: Helvetica Neue;
          margin-top: 5px;
          letter-spacing: -.35px;
        }
      }
    }
    &:hover {
      .icons {
       opacity: 1;
      }
     }
}
  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: 46px;
    z-index: 10;
    background-image: none;
  }
  .footerButton .mat-button {
    min-width: 42px;
  }
}
/******** Product-2 styles *********/
.product-item-2 {
  position: unset;
  height: auto;
  transition: .4s;
  padding: 26px 5px 5px 5px;
  .mat-card:not([class*=mat-elevation-z]) {

    box-shadow: 2px 2px 6px 0px rgba(0,0,0,.1), 0 1px 1px 0 rgba(0,0,0,.0), 0 1px 3px 0 rgba(0,0,0,.12) !important;
    padding: 0;
    .product-img {
      background: $baners-background;
      img {
        max-width: 180px;
        margin-bottom: 12px;
        display: block;
          margin-left: auto;
          margin-right: auto;
      }
      .icons {
        position: absolute;
        top: 30px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s ease;
        a {
          color: $font-color;
          cursor: pointer;
          transition: 0.5s ease;
          margin-bottom: 5px;


          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .product-info-wrapper {
      padding: 15px;



      .stars {
        color: $theme-main;
        margin: 2px 0 10px 0;
        span {
          color: $grey3;
          font-size: 13px;
          margin-left: 10px;
        }
        mat-icon{
          font-size: 17px;
            line-height: 17px;
            height: 17px;
            width: 17px;
            vertical-align: middle;
        }
      }

      .category{
        margin-bottom: 4px;
    display: block;
    font-weight: 300;
       }
       span {
        color: $grey5;
        font-size: 14px;

      }
      .price-wrap {
        p{
          font-size: 19px;
         }
         mat-icon{
          color: $white;
         }
         a{
          background-color: var(--theme-default);
          cursor: pointer;
          transition: .3s;
          width: 38px;
      height: 38px;
      border-radius: 50%;
      text-align: center;
      line-height: 46px;
          mat-icon {
            transition: .3s;
            font-size: 20px;
          line-height: 24px;
          }
         }
         a {
          background-color: var(--theme-default);
          cursor: pointer;
          transition: .3s;
          width: 38px;
      height: 38px;
      border-radius: 50%;
      text-align: center;
      line-height: 46px;

          mat-icon {
            transition: .3s;
            font-size: 18px;
          }

        }
      }
      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
        }
      }
    }
    &:hover {
      .icons {
       opacity: 1;
      }

     }
}
button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}
}
/******** Product-3 styles *********/
.product-item-3 {
  position: unset;
  height: auto;
  transition: .4s;
  padding: 26px 5px 5px 5px;
  .mat-card:not([class*=mat-elevation-z]) {

    box-shadow: 2px 2px 6px 0px rgba(0,0,0,.1), 0 1px 1px 0 rgba(0,0,0,.0), 0 1px 3px 0 rgba(0,0,0,.12) !important;
    padding: 0;
    .product-img {
      background: $baners-background;
      position: relative;
      overflow: hidden;
      img {
        max-width: 180px;
        margin-bottom: 12px;
        display: block;
          margin-left: auto;
          margin-right: auto;
      }

          .content {
            display: table-cell;
            vertical-align: bottom;
            overflow: hidden;

            .icons {
              position: absolute;
              bottom: 0;
              top: auto;
              left: 0;
              width: 100%;
              text-indent: 0;
              height: auto;
              padding: .75rem 0 .45rem 0;
              position: absolute;
              opacity: 0;
              visibility: hidden;
              background: var(--theme-default);
              z-index: 2;
              transition: .3s ease;
              -webkit-transform: translate3d(0, 35px, 0);
              transform: translate3d(0, 35px, 0);
              -webkit-transition: -webkit-transform .4s ease, visibility .4s ease, opacity .4s ease;
              transition: transform .4s ease, visibility .4s ease, opacity .4s ease;
              a {
                cursor: pointer;
                transition: 0.5s ease;
                line-height: 22px;


              }
            }
          }




    }
    .product-info-wrapper {
      padding: 15px;



      .stars {
        color: $theme-main;
        margin: 2px 0 10px 0;
        span {
          color: $grey3;
          font-size: 13px;
          margin-left: 10px;
        }
        mat-icon{
          font-size: 17px;
            line-height: 17px;
            height: 17px;
            width: 17px;
            vertical-align: middle;
        }
      }
      .category{
        margin-bottom: 4px;
    display: block;
    font-weight: 300;
       }
       span {
        color: $grey5;
        font-size: 14px;

      }
      .price-wrap {
        p{
          font-size: 19px;
         }
         mat-icon{
          color: $white;
         }
         .mat-mini-fab.mat-primary {
          box-shadow: none;
          mat-icon {
            transition: .3s;
            font-size: 20px;
          line-height: 24px;

          }
          background: var(--theme-default);
         }
         a {
          background: $theme-main;
          cursor: pointer;
          transition: .3s;
          width: 38px;
      height: 38px;
      background: $white-dark;
      border-radius: 50%;
      text-align: center;
      line-height: 46px;
      &:hover {
        background: $theme-main;
      }
          mat-icon {
            transition: .3s;
            font-size: 18px;
          }

        }
      }
      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
        }
      }
    }


    &:hover {

      .content {
        .icons {
          opacity: .85;
          visibility: visible;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
        }
      }

}

}
button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

}

    /***************************************
    ***************************************
    ***************************************
*  HOME-1 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-1 styles
****************************************/
.main-slider{
  height: 550px;
  .sl-wrap {
    position: relative;
    height: 100%;
    .content{
      height: 100%;
      position: relative;
      z-index: 9;
      /* padding-left: 294px; */
      vertical-align: middle;
      position: relative;
      /* top: 58%; */
      button {
      background-color: var(--theme-default);
        color: $white;
        padding: 5px 33px;
        width: 200px;
        box-shadow: none !important;
        span {
          font-size: 14px;
      font-weight: 700;
          text-transform: uppercase;
        }
      }
      h1{
        font-size: 48px;
        text-align: left;
        color: $font-color;
        text-transform: uppercase;
        letter-spacing: 3px;
    }
    h3{
        font-size: 36px;
        text-align: left;
        color: $font-color;
        margin-bottom: 30px;
        font-weight: 300;
        letter-spacing: 2px;
    }
    }
  }

  .slide-item{
    height: 100%;
    background-size: cover;
    background-position: center;
  }

}


.swiper-pagination-bullet-active {
  background: $theme-main !important;
}




      /* .mask{
          opacity: 0.6;
          width: 100%;
          height: 100%;
          position: absolute;
          overflow: hidden;
          z-index: 0;
          background-color: rgba(0,0,0,0.8);
      } */

  .swiper-lazy-preloader{
      top: 18%;
  }



.h-100 {
  height: 100%;
}

/***************************************
*  Home info bar styles
****************************************/
.info-bar{
  margin-top: 30px;
  border-bottom: 1px solid $grey6;
  .card-wrap{
    padding: 15px 0;
    .mat-card{
      height: 100px;
      box-shadow: none;
      border-right: 1px solid $grey6;
      &.no-bd {
        border-right: none;
      }
  }
  .mat-icon{
    color: $font-color;
    font-size: 41px;
    margin-right: 15px;
line-height: 23px;
  }
    }
}

          span{
              font-size: 14px;
          }


          .sidenav {
            width: 250px;
            // padding: 8px 16px;
            position: fixed !important;
            z-index: 999999999999999999 !important;
        }
        .mat-toolbar-multiple-rows {
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          width: 100%;
      }
     .welcome-message p {
        font-size: 13.5px;
        color: $grey7;
    }

    /***************************************
*  Header-1 styles
****************************************/


/*  Topbar styles */
.main-topbar {
  background: $white !important;
 .top-bar {

     .widget-text {
      padding: 9px 7px;
       a {
         color: $font-color;
         text-decoration: none;
         font-size: 13px;
         transition: .4s;
         .mat-icon {
           vertical-align: middle;
           margin-right: 1px !important;
           font-size: 23px;
         }
         &:hover {
           color: $grey10;
         }
       }
       &:not(:last-child) {
        margin: 0 20px 0 -15px;
       }
       .mat-button-wrapper {
         img {
           margin-right: 3px;
         }
       }
     }

   .welcome-message {
     p {
       color: $grey7;
       font-size: 13.5px;
       font-weight: 400;
     }
   }
   span {
     .mat-icon {
       color: $theme-main;
     }
   }
   .cur-icon {
     color: $grey7 !important;
   }
  }
}
/*  header styles */
.home-header {

  &.yellow {
display: none;
  }

  .kuro {
    background-color: var(--theme-default);;
    padding: 13px 0;
  }
  .call-bucket-wrap {
    .info-message-icon {
      color: #fff !important;
    }
  }
.top-navbar {
  background: transparent !important;
  .mat-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff !important;
    .top-cart {
      span {
        font-size: 15px;
      }
    }

  }
  // button {
  //   background: transparent !important;
  // }
 }

.logo {
  padding: 25px 0;
}
 .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
  .form-wrap{
    background-color: transparent;
      white-space: nowrap;
    zoom: 1;
    width: 100%;
    margin: 0;
    position: relative;
    background: $grey4;
    background: rgba(0,0,0,.2);
    input {
      border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border-color: $white;
        -webkit-transition: box-shadow .3s ease-out;
        transition: box-shadow .3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
    }
  }
  .btn-search {
    overflow: visible;
    position: absolute;
    border: 0;
    margin: 0;
    cursor: pointer;
    height: 100%;
    min-width: 50px;
    right: -15px;
    top: 0;
    padding: 0 35px;
    color: $white;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    text-transform: uppercase;
    background-color: $font-color;
    border-radius: 0 3px 3px 0;
    -webkit-border-radius: 0 3px 3px 0;
    text-shadow: 0 -1px 0 rgba(0,0,0,.3);
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
  }

 }

.call-bucket-wrap {
  .info-message-icon {
    .icon {
      mat-icon {
        font-size: 35px;
        /* color: #fff; */
        display: block;
        float: left;
        height: 49px;
        margin: 0 16px 0 0;
       }
    }
    .info-text {
      p{
        font-size: 12px;
        line-height: 0px;
        margin-bottom: 7px;
       }
       span{
        font-size: 20px;
          font-weight: 600;

       }
    }
  }
}
.cart-count-wrapper{
  position: absolute;
  top: -10px;
  left: 30px;
  background: $dark-font ;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}
}


.home-header-yellow {
  background: #fbc02d;
  padding: 13px 0;
  &.green{
    display: none;
}
&.blue{
  display: none;
}
&.red{
  display: none;
}
&.pink{
  display: none;
}
&.purple{
  display: none;
}
&.grey{
 display: none;
}
&.orange{
  display: none;
}
  .kuro {
    background-color: var(--theme-default);
  }
  .call-bucket-wrap {
    .info-message-icon {

    }
  }
.top-navbar {
  background: transparent !important;
  .mat-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .top-cart {
      span {
        font-size: 15px;
      }
    }
    .commerce-buttons {
      .flex-row-button {

        .mat-button-wrapper {
          .cart-count-wrapper{
            position: absolute;
            top: -10px;
            left: 30px;
            background: $white ;
            height: 18px;
            width: 18px;
            line-height: 18px;
            border-radius: 50%;
            font-size: 11px;
          }
        }
      }


      }
  }
 }

.logo {
  padding: 25px 0;
}
 .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
  .form-wrap{
    background-color: transparent;
      white-space: nowrap;
    zoom: 1;
    width: 100%;
    margin: 0;
    position: relative;
    background: $grey4;
    background: rgba(0,0,0,.2);
    input {
      border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border-color: $white;
        -webkit-transition: box-shadow .3s ease-out;
        transition: box-shadow .3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
    }
  }
  .btn-search {
    overflow: visible;
    position: absolute;
    border: 0;
    margin: 0;
    cursor: pointer;
    height: 100%;
    min-width: 50px;
    right: -15px;
    top: 0;
    padding: 0 35px;
    color: $white;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    text-transform: uppercase;
    background-color: $font-color;
    border-radius: 0 3px 3px 0;
    -webkit-border-radius: 0 3px 3px 0;
    text-shadow: 0 -1px 0 rgba(0,0,0,.3);
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
  }

 }

.call-bucket-wrap {
  .info-message-icon {
    .icon {
      mat-icon {
        font-size: 35px;
       color: $black;
        display: block;
        float: left;
        height: 49px;
        margin: 0 16px 0 0;
       }
    }
    .info-text {
      color: $black;
      p{
        font-size: 12px;
        line-height: 0px;
        margin-bottom: 7px;
       }
       span{
        font-size: 20px;
          font-weight: 600;

       }
    }
  }
}
.cart-count-wrapper{
  position: absolute;
  top: -10px;
  left: 30px;
  background: $white ;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}
}
/*  shopping widgets styles */



.spCard-main {
  width: 100%;
  .mat-menu-content {
    width: 100% !important;

}
  .spCard-dropdown {
    background-color: $white;
      padding: 15px 10px;
      max-width: 300px;
      z-index: 400;
      .card-list-title {
       padding: 0 0 10px 0;
      }
      h4 {
        padding-bottom: 10px;
      }
      .new-product {
        .product {
          img {
            width: 70px;
          }
        }
        .close-circle {
          flex: 1;
          a{
            cursor: pointer;
            color: $grey3;
            transition: .3s;
            float: right;
            &:hover {
              color: $theme-main;
            }
            i {
              font-size: 20px;
            }
          }
        }
        .item {
          padding: 10px 0;
          .product {
            margin-right: 7px;
            background: $baners-background;
            border-radius: 3px;
          }
          .desc {
            p {
              font-weight: 500;
             }
             span {
              color: $grey;
                font-size: 15px;
                margin-top: 5px;
                /* padding-top: 7px; */
                display: block;
            }
          }
        }
        .total {
          padding-top: 15px;
          p {
            font-size: 15px;
          }
          span {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
      .card-list-title {
        a{
          color: $grey4;
          font-size: 14px;
          font-weight: 500;
         }
         p{
          color: $grey4;
          font-size: 14px;
          font-weight: 500;
         }
      }

  .woo-message {
    text-align: center;
      padding: 0;
      margin: 1.5em 0 0 0;
  }
  }
}




    /***************************************
*  Shopping widget styles
****************************************/





.card-list-title2 {
  padding: 5px 0 15px 0;
}


/***************************************
*  Home product section styles
****************************************/

  .products {
    position: relative;
    .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
      pointer-events: auto;
  }
    .product-wraper{
      padding: 20px 0px 20px 20px;
      .product-box {
        width: 100%;


      }
    }
  //   .swiper-slide {
  //  width: 245.2px !important;
  //  margin-right: 16px;
  //   }

  }





/***************************************
*  Home baners section styles
****************************************/
.banners-container {
  .baners {
    background: $baners-background !important;
    margin-right: 15px;
  }
  .bn-wrap {
    margin-bottom: 18px;
  }
  .baner {

    height: 100%;
    transition: .15s ease-in;
    text-align: left;
    .info {
      padding: 20px;
      h4{
        font-size: 21px;
        font-weight: 600;
        color: $grey-dark;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
    }

    h4.big-title {
      font-size: 37px;
      font-weight: 500;
      color: $grey-dark;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .info {
      .big-price {
      padding: 10px 25px !important;
      box-shadow: none !important;
      width: 135px;
          border-radius: 5px;
          text-align: center;
          span {
            font-size: 27px !important;
          }
      }
    }
    p{
      color: $theme-main-dark
    }
    a{
      font-size: 16px;
      font-weight: 700;
     text-decoration: none;
     color: $theme-main-dark;
     margin-top: 15px;
     display: block;
    }
  }
  .first {
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 4px;
  }
  .seccond {
    /* height: 200px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    margin: 0 10px;
    background-color: $gray-lighter;
  }
}

/***************************************
*  Home vertical-product section styles
****************************************/
.vertical-products {
  .widget-column {
    .title {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;
      .widget-title {
        font-size: 21px;
        font-weight: 500;
        &::before {
          content: "";
          left: 0;
          position: absolute;
          width: 100%;
          height: 0.0625rem;
          bottom: 0.34375rem;
          background-color:$gray-lighter;
        }
        &::after {
          content: "";
          left: 0;
          position: absolute;
          width: 2.5rem;
          height: 0.125rem;
          background-color:$dark-font;
          bottom: 0.3125rem;
        }
      }
    }
    .media-image {
      background: $gray-lighter;
      margin-right: 20px;
      width: 80px;
     height: 80px;
     border-radius: 3px;
      .small {
        width: 100%;
      }
    }
    .media-body {
      span {
        font-size: 17px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 1;
    color: $font-color;
      }
      .stars {
        mat-icon {
          color: $theme-main;
          font-size: 17px;
          line-height: 17px;
          height: 17px;
          width: 17px;
          vertical-align: middle;
      }
      }
      p.price {
        margin-top: 5px;
      }
    }
    .product-list {
      li:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }
  .product-panel:not(:last-child) {
    padding-right: 30px;
  }
}

/***************************************
*  Home footer section styles
****************************************/
.footer {
  background-color: $footer-background;

  .footer-widget {
    padding-right: 15px;
    .social {
      margin-top: 20px;
      li {
        width: 33px;
        height: 33px;
        border: 1px solid $grey4;
        border-radius: 50%;
        text-align: center;
        margin-right: 5px;
        a {

          i {
            color: $theme-main-dark;
          }
        }

      }
    }
    .ped-btn-footer {
           box-shadow: none !important;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 20px;
      padding: 0px 10px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.4s ease 0s;
      z-index: 222;
      background-color: var(--theme-default);
      color: $white;
      border-radius: 3px;
      line-height: 45px;
    }
    .title {
      margin-bottom: 10px;
      h5 {
        font-size: 18px;
        font-weight: 600;
      }
      }

      a {
        text-decoration: none;
        font-size: 15px;
        line-height: 32px;
        color: $font-color;
      }


        .newsletter-widget {
          position: relative;
          margin: 15px 0 20px 0;
        }

        p {
          line-height: 24px;
          color: $font-color;
        }
        ul {
          list-style-type: none;
        }
        .about-widget {
          .logo {
            img {
              max-width: 240px;
            }
          }
        }
        .tel-box {
          .icon {
            i {
              color: $theme-main-dark;
              display: block;
              line-height: 27px;
              vertical-align: middle;
              margin-right: 5px;
              width: 18px;
              text-align: center;
              font-size: 18px;
            }
          }
          .text {
            p {
              a {
                line-height: 22px;
              }

            }
          }
        }
  }

  .subscribe-widget {
    input {
      height: 45px;
      background: $white;
      font-size: 15px;
      line-height: 45px;
      border: 1px solid $white-dark;
      border-radius: 4px;
      padding: 0 65px 0 20px;
      color: $grey;
    }
  }
}



    /***************************************
    ***************************************
    ***************************************
*  HOME - 2 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-2 styles
****************************************/
.carousel-2 {
  padding-top: 25px;
  .ctn-box {
    box-sizing: content-box !important;
  }

    .main-slider {
      height: 461px;
      .sl-wrap {
        position: relative;
        height: 100%;
        .content {
          padding-left: 20px;
          padding-right: 400px;

            height: 100%;
            position: relative;
            z-index: 9;
            /* padding-left: 294px; */
            vertical-align: middle;
            position: relative;
            /* top: 58%; */
            transform: none;
        }
      }

    }
  .slider-categories {
    padding-right: 15px;
  .categories-wrap {
    border: 1px solid $grey8;
    .title {
      background: $baners-background;
    border-bottom: 1px solid $grey-light;
    color: $grey4;
    padding: 15px 20px 15px 10px;
    font-weight: 500;
    font-size: 14.5px;
    }
    .category-contant {
      ul {
        li {
          display: block;
          a {
            padding-top: 13px;
    padding-bottom: 13px !important;
    border-top: 1px solid $grey8;
    margin: 0 10px -1px;
          }
          .no-brd {
            border-top: none;
          }
        }
      }
    }
    .btn-sale {
      margin: 20px 10px;
      button {
        padding: 5px 33px;
        width: 100%;
        box-shadow: none;
      }
    }
  }


}
.carousel-2-wrapper {
  max-width: 70%;
  .main-slider {
    height: 461px;
    .content {
      padding-left: 20px;
      padding-right: 485px;
    }
  }
}
}




/***************************************
*  Header-2 styles
****************************************/


.home-header-2 {
  background: $white;
  .logo {
    padding: 25px 0;
  }
  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;
    .form-wrap{
      background-color: transparent;
        white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: $grey4;
      background: rgba(0,0,0,.0);
      input {
        border-style: none;
          border-width: 1px;
          border-radius: 3px;
          border: 1px solid $grey2;
          -webkit-transition: box-shadow .3s ease-out;
          transition: box-shadow .3s ease-out;
          height: 40px;
          width: 100%;
          max-width: 100%;
          padding: 0 0 0 12px;
          float: left;
          outline: none;
          box-shadow: none;
      }
      .btn-search {
        overflow: visible;
        position: absolute;
        border: 0;
        margin: 0;
        cursor: pointer;
        height: 100%;
        min-width: 50px;
        right: -15px;
        top: 0;
        padding: 0 35px;
        color: $white;
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -ms-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
        text-transform: uppercase;
        background-color: $font-color;
        border-radius: 0 3px 3px 0;
        -webkit-border-radius: 0 3px 3px 0;
        text-shadow: 0 -1px 0 rgba(0,0,0,.3);
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-appearance: none;
      }
    }
  }
  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          /* color: #fff; */
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
         }
      }
      .info-text {
        p{
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
         }
         span{
          font-size: 20px;
            font-weight: 600;

         }
      }
    }
    .main-menu-wrapper-2 {
      .top-navbar {
        background: $white;
        .mat-button-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .top-cart {
            span {
              font-size: 15px;
            }
          }
        }
        .commerce-buttons {
          .mat-button-wrapper {
            .cart-count-wrapper{
              position: absolute;
              top: -10px;
              left: 30px;
              color: $white;
              background-color: var(--theme-default);
              height: 18px;
              width: 18px;
              line-height: 18px;
              border-radius: 50%;
              font-size: 11px;
            }
          }
        }
       }
    }
  }
}


/***************************************
*  Header-5 styles
****************************************/

/*  header styles */
.home-header-five {
  background: $white;


  .header-wrapper {
    border-bottom: 1px solid $grey6;
    padding: 20px 0;
    .phone-wrap {

      .info-text {
        p{
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
         }
         span{
          font-size: 20px;
            font-weight: 600;

         }
      }
    }

  .top-navbar {
    background: transparent;
    .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-cart {
        span {
          font-size: 15px;
        }
      }
    }
   }



  .thm-button {
    button {
      color: $white !important;
      border-radius: 0;
      padding: 4px 40px;

    }
  }
  }

}

/*  menu styles */
.menu-wraper-five {
  background: $white;
  .menu-container {
    padding: 14px 0;
    height: 67px;
    .mat-toolbar-row {
      height: 100%;
    }
    .main-menu {
      margin-left: -15px;
      a {
        text-transform: uppercase;
        color: $grey7;
      }
    }
    .call-bucket-wrap {
      padding-top: 10px;
      .top-navbar {
        flex-direction: row !important;
                box-sizing: border-box;
                display: flex;
        background: transparent !important;
        .info-message-icon {
        .icon {
          mat-icon {
            font-size: 35px;
            /* color: #fff; */
            display: block;
            float: left;
            height: 49px;
            margin: 0 16px 0 0;
           }
        }

      }

    /*  shopping widgets styles */
  .commerce-buttons {
    margin-top: 7px;
    .flex-row-button {

      .mat-button-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .cart-count-wrapper{
          position: absolute;
          top: -10px;
          left: 30px;
          background: var(--theme-default);
          height: 18px;
          width: 18px;
          line-height: 18px;
          border-radius: 50%;
          font-size: 11px;
          color: $white;
        }
      }
    }
  .badge {
    .mat-badge-content {
      color: #fff;
    }
  }

    }

    .search-form {
    margin-right: 20px;
    .mat-form-field-wrapper {

      .mat-form-field-underline {
        background-color: rgba(0,0,0,0.07) !important;
    }
    .mat-form-field-label {
      display: none;
    }
    }

    }
    .devider2 {
      height: 31px;
      width: 1px;
      background: $grey6;
      margin: 0 20px;
    }
      }

    }
  }
}

.spCard-main {
  width: 100%;
  .mat-menu-content {
    width: 239px !important;

}
  .spCard-dropdown {
    background-color: $white;
      padding: 15px 10px;
      max-width: 300px;
      z-index: 400;
      .card-list-title {
       padding: 0 0 10px 0;
      }
      h4 {
        padding-bottom: 10px;
      }
      .new-product {
        .product {
          img {
            width: 70px;
          }
        }
        .close-circle {
          flex: 1;
          a{
            cursor: pointer;
            color: $grey3;
            transition: .3s;
            float: right;

            i {
              font-size: 20px;
            }
          }
        }
        .item {
          padding: 10px 0;
          .product {
            margin-right: 7px;
            background: #ffffff;
            /* border-radius: 3px; */
            border: 1px solid #e7e7e7;
          }
          .desc {
            p {
              font-weight: 500;
             }
             span {
              color: $grey;
                font-size: 15px;
                margin-top: 5px;
                /* padding-top: 7px; */
                display: block;
            }
          }
        }
        .total {
          padding-top: 15px;
          p {
            font-size: 15px;
          }
          span {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
      .card-list-title {
        a{
          color: $grey4;
          font-size: 14px;
          font-weight: 500;
         }
         p{
          color: $grey4;
          font-size: 14px;
          font-weight: 500;
         }
      }

  .woo-message {
    text-align: center;
      padding: 0;
      margin: 1.5em 0 0 0;
  }
  }
}

    /***************************************
*  Home-2 info bar styles
****************************************/
.info-bar{
  margin-top: 30px;
  border: 1px solid $grey6;
  .card-wrap{
    padding: 15px 0;
    .mat-card{
      height: 100px;
      box-shadow: none;
      border-right: 1px solid $grey6;
      box-shadow: none !important;
      background: transparent !important;
      .content {
        p {
          font-weight: 600;
        font-size: 14.5px;
        }
      }
      &.no-bd {
        border-right: none;
      }
  }
  .mat-icon{
    color: var(--theme-default);
    font-size: 41px;
    margin-right: 10px;
    line-height: 65px;
    clear: both;
    /* display: block; */
    width: auto;
    height: 100%;
    /* vertical-align: middle; */
    padding: 0 3px;
  }
    }
}
/***************************************
*  Home-2 product section styles
****************************************/
.home-2-wrap {
  .products-section {
    background: $grey6;
 .products {
   padding: 0 !important;

  .product-wraper{
    .product-box {
      .product-item {
        width: 240px;
        .product-img {
          background: $white;
        }
      }
    }
  }
  .mat-tab-labels {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
 }
 h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 23px;
  font-weight: 580;
  position: absolute;
 }
}
}



 /***************************************
    ***************************************
    ***************************************
*  HOME - 3 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-3 styles
****************************************/

.carousel-3 {
  background: $white;
  .ctn-box {
    box-sizing: content-box !important;
  }
  padding: 25px 0;
  .carousel-2-wrapper {
  //  max-width: 100% !important;
    .main-slider {
      height: 461px;
      .sl-wrap {
        position: relative;
        height: 100%;
        .content {
          padding-left: 20px;
          padding-right: 400px;

            height: 100%;
            position: relative;
            z-index: 9;
            /* padding-left: 294px; */
            vertical-align: middle;
            position: relative;
            /* top: 58%; */
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 20px;
          height: 5px;
          display: inline-block;
          border-radius: 13px;
          background: $dark-font;
          opacity: .6;
      }
      }
    }
    max-width: 70%;
}
.slider-categories {
  padding: 0 !important;
  .carousel-banners-wrapper {
    padding-left: 15px;
    .carousel-banner {

      width: 100%;
      height: 223px;
      background: $baners-background;
      .seccond {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 4px;
        margin: 0 10px;
        .info {
          padding: 40px 0;
          .bn-img {
            img {
              width: 100%;
              height: auto;
            }
          }
          .text-box {
            h4 {
              font-size: 24px;
              font-weight: 700;
              text-transform: uppercase;
            }
            button {
              color: var(--theme-default);
              text-decoration: underline;
              cursor: pointer;
              outline: none;
              border: none;
              -webkit-tap-highlight-color: transparent;
              display: inline-block;
              white-space: nowrap;
              vertical-align: baseline;
              text-align: left;
              margin-top: 21px;
              min-width: auto !important ;
              line-height: 0 !important;
              padding: 0 !important;
              border-radius: 4px;
              overflow: visible;
              box-shadow: none !important;
            }
          }

        }
      }
    }
  }
}
}

.home-3-wrap {
  .home3-sidebar {
    padding-right: 15px;
    .categories-wrap {
      border: 1px solid $grey-light;
      margin-bottom: 25px;
      .title {
        background: $baners-background;
      border-bottom: 1px solid $grey-light;
      color: $grey4;
      padding: 15px 20px 15px 10px;
      font-weight: 500;
      font-size: 14.5px;
      }
      .category-contant {
        ul {
          li {
            display: block;
            a {
              padding-top: 13px;
              padding-bottom: 13px !important;
              border-top: 1px solid $grey-light;
              margin: 0 10px -1px;
            }
            .no-brd {
              border-top: none;
            }
          }
        }
      }
      .btn-sale {
        margin: 20px 10px;
        button {
          padding: 5px 33px;
          width: 100%;
          box-shadow: none !important;
        }
      }
    }
    .brands {
      border: 1px solid $grey-light;
      padding: 15px 20px;
      margin-bottom: 25px;
      .title-header {
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 1.25rem;
        h4 {
          &::before {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 0.0625rem;
            bottom: 0.34375rem;
            background-color: $grey6;
          }
          &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 2.5rem;
            height: 0.125rem;
            background-color: $font-color;
            bottom: 0.3125rem;
          }
        }
      }
      .brand-logos {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-row-gap: 1em;
        grid-column-gap: 1em;
        .brand-item {
          display: inline-block;
          text-align: center;
          vertical-align: top;
          overflow: hidden;
          position: relative;
          margin-right: 10px;
          a {
            img {
              width: 100%;
            }
          }
        }
      }

    }
    .mat-toolbar {
      height: 17%;
      margin-bottom: 25px;
      background-color: var(--theme-default);
      .sale-widget {
        padding: 15px 20px;
        text-align: center;
        margin-bottom: 25px;
        .sale-widget1 {
          color: $white;
          font-size: 36px;
           font-weight: 600;
           display: block;
           letter-spacing: .5;
        }
        .sale-widget2 {
          color: $white;
          font-size: 18px;
           font-weight: 600;
           display: block;
           letter-spacing: .5;
        }
        .discount {
          display: inline-block;
          color: $grey-dark;
          font-size: 77px;
          font-weight: 600;
          position: relative;
          letter-spacing: .5;
        }
        .percent {
          display: inline-block;
          line-height: 31px;
          span {
            display: block;
            font-size: 2.5em;
            font-weight: 500;
          }
        }
        p {
          color: $white;
          line-height: 24px;
          font-weight: 300;
          font-size: 15px;
        }
      }
    }

    .subscribe-widget {
      padding: 15px 20px;
      background: $gray-lighter;
      text-align: center;
      span {
        font-weight: 600;
        display: block;
      }
      p {
        color: $grey4;
        line-height: 24px;
        font-weight: 300;
        font-size: 15px;
      }
      .form {
        position: relative;
        margin-top: 20px;
        .newsletter-widget {
           input {
            height: 45px;
            background: $white;
            font-size: 15px;
            line-height: 45px;
            border: 1px solid $grey6;
            border-radius: 4px;
            padding: 0 65px 0 20px;
            $grey: $grey;
           }
           .ped-btn-footer {
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            margin-top: 10px;
            padding: 0px 10px;
            text-align: center;
            text-transform: uppercase;
            transition: all 0.4s ease 0s;
            z-index: 222;
            width: 100%;
            border-radius: 3px;
            line-height: 45px;
            box-shadow: none !important;
        }
        }
      }
    }
  }
  .main-home3-wrapper {
    padding-left: 15px;
    .info-bar{
      margin-top: 0px;
      border: 1px solid $grey6;
      .card-wrap{
        padding: 15px 0;
        .mat-card{
          height: 100px;
          box-shadow: none;
          border-right: 1px solid $grey6;
          padding: 9px;
          .content {
            p {
              font-weight: 600;
            font-size: 14.5px;
            }
          }
          &.no-bd {
            border-right: none;
          }
          .content {
            p {
              font-weight: 600;
              font-size: 14.5px;
            }
          }
      }
      .mat-icon{
        font-size: 41px;
        margin-right: 10px;
        line-height: 65px;
        width: 25%;
      }
        }
    }


   .products {
    background: $white;
    position: relative;

     .product-wraper{
      .product-box {
        .product-item {
          width: 227px;
        }
      }
    }

     .mat-tab-body-wrapper {
      .mat-tab-body {
        .mat-tab-body-content {
          height: 100%;
      }
      }

     }

    .mat-tab-labels {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
  }
   }
   h3 {
    margin-bottom: 0;
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    position: absolute;
    top: 69px;
   }


  .home3-banner-wrap {
    width: 100%;
    .banner {
      position: relative;
      .text-wrap {
        position: absolute;
        top: 33px;
        left: 35px;

        font-size: 95%;
        z-index: 9999999999999;
        .subtitle {
          color: $white;
        }
        .main {
      color: $theme-main;
      font-size: 38px;
      font-weight: 600;

    }
    a {
      border: 1px solid $theme-main;
      color: $font-color !important;
      cursor: pointer;
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      margin-top: 14px;
      padding: 0px 10px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.4s ease 0s;
      z-index: 222;
      background: $theme-main;
      width: 120px;
      border-radius: 3px;
      line-height: 40px;
    }
      }
      img {
        position: relative;
        display: block;
        height: auto;
        max-width: 100%;
        width: calc(100% + 60px);
      }
    }
  }
  }


}





.menu-container1 {
  background: $grey9;
   a.mat-button {
     text-transform: uppercase;
     color: $theme-main-dark;
    font-size: 1em;
    font-weight: 700;
    padding: 0 25px;
    line-height: 63px;
   }

}


/***************************************
*  Menu-2 styles
****************************************/

.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  // padding: 0 13px !important;
    border-top: 4px solid $theme-main !important;
  .mat-menu-item {
    border-bottom: 1px dashed $gray-lighter;
  }
}
  .top-navbar a{
    padding: 15px 26px;
  }


  .menu-container2 {
    background: var(--theme-default);;

    .mat-toolbar {
      background: transparent !important;
    }
     a.mat-button {
       text-transform: uppercase;
       color: $white !important;
      font-size: 1em;
      font-weight: 700;
      padding: 0 25px;
      line-height: 63px;
     }
  }


/***************************************
*  Sidebar styles
****************************************/


.mat-drawer {
  width: 300px;
  // padding: 8px 14px;
  position: fixed;
  margin-left: 0px;
}
.toggle-header {
  height: 64px;
  background: $theme-main-red;
}
.sidebar-menu {
  margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $grey2;
  .menu-item {
    a {
      color: $theme-main-dark;
      padding: 8px 15px;
    font-size: 16px;
    display: block;
    }
  }
}


/***************************************
*  Products left sidebar page styles
****************************************/


  .left-sidebar-wrapper mat-sidenav.filter-sidenav.mat-drawer.mat-sidenav.ng-tns-c15-4.ng-trigger.ng-trigger-transform.ng-tns-c13-3.ng-star-inserted.mat-drawer-over {
    padding-right: 10px;
  }

  .left-sidebar-wrapper {
    .mat-expansion-panel {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border: 1px solid $grey-light;
      box-shadow: none !important;
      margin-bottom: 20px;
      background: transparent !important;
      .mat-expansion-panel-header-title span {
        font-size: 17px;
        text-transform: capitalize;
        font-weight: 500;

        line-height: 1;
      }
    }

    .filter-sidenav {
      width: 280px;
      border: 0 !important;
      .brands {

        .title-header {
          margin-bottom: 15px;
          position: relative;
          padding-bottom: 1.25rem;
          h4 {
            &::before {
              content: "";
              left: 0;
              position: absolute;
              width: 100%;
              height: 0.0625rem;
              bottom: 0.34375rem;
              background-color: $grey6;
            }
            &::after {
              content: "";
              left: 0;
              position: absolute;
              width: 2.5rem;
              height: 0.125rem;
              background-color: $font-color;
              bottom: 0.3125rem;
            }
          }
        }
        .brand-logos {
          display: grid;
          grid-template-columns: repeat(2,1fr);
          grid-row-gap: 1em;
          grid-column-gap: 1em;
          .brand-item {
            display: inline-block;
            text-align: center;
            vertical-align: top;
            overflow: hidden;
            position: relative;
            margin-right: 10px;
            a {
              img {
                width: 100%;
              }
            }
          }
        }

      }
      .popular-products {
        margin-top: 20px;
      }
    }
    .filter-row {
      padding: 6px 25px 0px;
      border-bottom: 1px solid #e4e4e4;
      border-top: 1px solid #e4e4e4;
      margin-bottom: 20px;
      box-shadow: none !important;
    }
    .all-products {
      padding-left: 17px;
    select {
      /* margin: 50px; */
      width: 150px;
      padding: 5px 35px 5px 5px;
      font-size: 16px;
      border: none;
      height: 34px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;

      cursor: pointer;
      background: url(/assets/images/dropdown.png) no-repeat 90%;
    }
      .col {
        padding: 9px 0 9px 0px;

            .product-item {
              position: relative;
              height: auto;
              padding: 0 10px;
              transition: .4s;
              .mat-card:not([class*=mat-elevation-z]) {

                box-shadow: 2px 2px 6px 0px rgba(0,0,0,.1), 0 1px 1px 0 rgba(0,0,0,.0), 0 1px 3px 0 rgba(0,0,0,.12) !important;
                padding: 0;
                .product-img {
                  background: $gray-lighter;
                  img {
                    max-width: 180px;
                    margin-bottom: 12px;
                    display: block;
                      margin-left: auto;
                      margin-right: auto;
                  }
                  .icons {
                    position: absolute;
                    top: 30px;
                    right: 10px;
                    opacity: 0;
                    transition: all 0.5s ease;
                    a {
                      color: $font-color;
                      cursor: pointer;
                      transition: 0.5s ease;
                      margin-bottom: 5px;

                    }
                  }
                }
                .product-info-wrapper {
                  padding: 15px;



                  .stars {
                    color: $theme-main;
                    margin: 2px 0 10px 0;
                    span {
                      color: $grey3;
                      font-size: 13px;
                      margin-left: 10px;
                    }
                    mat-icon{
                      font-size: 17px;
                        line-height: 17px;
                        height: 17px;
                        width: 17px;
                        vertical-align: middle;
                    }
                  }
                  .category{
                    margin-bottom: 4px;
                display: block;
                font-weight: 300;
                   }
                   span {
                    color: $grey5;
                    font-size: 14px;

                  }
                  .price-wrap {
                    p{
                      font-size: 19px;
                     }
                     mat-icon{
                      color: $white;
                     }
                     a {
                      cursor: pointer;
                      transition: .3s;
                      width: 38px;
                  height: 38px;
                  background:var(--theme-default);
                  border-radius: 50%;
                  text-align: center;
                  line-height: 46px;

                      mat-icon {
                        transition: .3s;
                        font-size: 18px;
                      }

                    }
                    .mat-mini-fab.mat-primary {
                      box-shadow: none;
                      .mat-icon {
                        transition: 0.3s;
                        font-size: 20px;
                        line-height: 24px;
                      }
                    }
                  }
                  .title-wrap {
                    h4 {
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      font-weight: 500;
                    }
                  }
                }
                &:hover {
                  .icons {
                   opacity: 1;
                  }

                 }
            }

            }

      }
    }
    .no-shadow {
      box-shadow: none !important;
    }
  }

  /***************************************
*  Products left sidebar page styles
****************************************/

.right-sidebar-wrapper {
  .mat-expansion-panel {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid $grey-light;
    box-shadow: none !important;
    margin-bottom: 20px;
    background: transparent !important;
    .mat-expansion-panel-header-title span {
      font-size: 17px;
      text-transform: capitalize;
      font-weight: 500;

      line-height: 1;
    }
  }

  .filter-sidenav {
    width: 280px;
    border: 0 !important;
    .brands {

      .title-header {
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 1.25rem;
        h4 {
          &::before {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 0.0625rem;
            bottom: 0.34375rem;
            background-color: $grey6;
          }
          &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 2.5rem;
            height: 0.125rem;
            background-color: $font-color;
            bottom: 0.3125rem;
          }
        }
      }
      .brand-logos {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-row-gap: 1em;
        grid-column-gap: 1em;
        .brand-item {
          display: inline-block;
          text-align: center;
          vertical-align: top;
          overflow: hidden;
          position: relative;
          margin-right: 10px;
          a {
            img {
              width: 100%;
            }
          }
        }
      }

    }
    .popular-products {
      margin-top: 20px;
    }
  }
  .filter-row {
    padding: 6px 25px 0px;
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
    margin-bottom: 20px;
    box-shadow: none !important;
  }
  .all-products {
    // padding-right: 17px;
  // background: $white;
  select {
    /* margin: 50px; */
    width: 150px;
    padding: 5px 35px 5px 5px;
    font-size: 16px;
    border: none;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;

    cursor: pointer;
    background: url(/assets/images/dropdown.png) no-repeat 90%;
  }
    .col {
      padding: 9px 0 9px 0px;
          .product-box{
            min-height: 440px;
            width: 400px;
            margin-left: 9px!important;
            margin-right: 9px!important;
            box-shadow: 0 2px 14px #888887;
          }
          .product-item {

            .mat-card:not([class*=mat-elevation-z]) {

              box-shadow:none !important;
              padding: 0;
              .product-img {
                background: $gray-lighter;
                display: table-cell;
                vertical-align: middle;
                height: 306px;
                width: 299px;
                margin: 0 auto;
                img {
                     /* max-height: 100%; */
                      max-width: 85%;
                      padding: 25px;
                      margin: auto;
                }
                .icons {
                  position: absolute;
                  top: 30px;
                  right: 10px;
                  opacity: 0;
                  transition: all 0.5s ease;
                  a {
                    color: $font-color;
                    cursor: pointer;
                    transition: 0.5s ease;
                    margin-bottom: 5px;

                  }
                }
              }
              .product-info-wrapper {
                padding: 15px;



                .stars {
                  color: $theme-main;
                  margin: 2px 0 10px 0;
                  span {
                    color: $grey3;
                    font-size: 13px;
                    margin-left: 10px;
                  }
                  mat-icon{
                    font-size: 17px;
                      line-height: 17px;
                      height: 17px;
                      width: 17px;
                      vertical-align: middle;
                  }
                }
                .category{
                  margin-bottom: 4px;
              display: block;
              font-weight: 300;
                 }
                 span {
                  color: $grey5;
                  font-size: 14px;

                }
                .price-wrap {
                  p{
                    // font-size: 19px;
                   }
                   mat-icon{
                    color: $white;
                   }
                   a {
                    background-color: var(--theme-default);
              cursor: pointer;
              transition: .3s;
              width: 38px;
          height: 38px;
          border-radius: 50%;
          text-align: center;
          line-height: 46px;

                    mat-icon {
                      transition: .3s;
                      font-size: 18px;
                    }

                  }
                  .mat-mini-fab.mat-primary {
                    box-shadow: none;
                    .mat-icon {
                      transition: 0.3s;
                      font-size: 20px;
                      line-height: 24px;
                    }
                  }
                }
                .title-wrap {
                  h4 {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-weight: 500;
                  }
                }
              }
              &:hover {
                .icons {
                 opacity: 1;
                }

               }
          }

          }

    }
  }
  .no-shadow {
    box-shadow: none !important;
  }
}




    /***************************************
    ***************************************
    ***************************************
*  HOME - 4 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-4 styles
****************************************/

.home-header-three {
  background: $white;
  border-top: 1px solid $baners-background;
.logo {
    padding: 25px 0;
}
  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;
    .form-wrap {
      background-color: transparent;
      white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: #444;
      background: rgba(0, 0, 0, 0.2);
      input {
        border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border: 2px solid #dcdcdc;
        transition: box-shadow 0.3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
    }
    .btn-search {
      overflow: visible;
      position: absolute;
      border: 0;
      margin: 0;
      cursor: pointer;
      height: 100%;
      min-width: 50px;
      right: -15px;
      top: 0;
      padding: 0 35px;
      transition: all 250ms ease-in-out;
      text-transform: uppercase;
      background-color: var(--theme-default);
      border-radius: 0 3px 3px 0;
      -webkit-border-radius: 0 3px 3px 0;
      text-shadow: none;
      box-shadow: none !important ;
      -webkit-appearance: none;
  }
    }
  }
  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          /* color: #fff; */
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
      }
      }
      .info-text {
        p {
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
        }
        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  .main-menu-wrapper-3 {

      .top-navbar {
        background: transparent;
        .flex-row-button {
          background: transparent;
          .mat-button-wrapper {
            display: flex;
          flex-direction: row;
          align-items: center;
          .top-cart {
            span {
              font-size: 15px;
          }
          }
            .cart-count-wrapper {
              position: absolute;
              top: -10px;
              left: 30px;
              background: var(--theme-default);
              height: 18px;
              width: 18px;
              color: $white;
              line-height: 18px;
              border-radius: 50%;
              font-size: 11px;
          }
          }
        }
      }


  }
  }

}

.menu-container4 {
  background: $white;
  border-top: 1px solid #e5e5e5;
  .main-menu {
    margin-left: -15px;
    margin: 0 auto;
    a.mat-button {
      text-transform: uppercase;
      color:$white;
      font-size: 1em;
      font-weight: 700;
      padding: 0 25px;
      line-height: 63px;
    }
}
}

.topbar_four {
  background: $baners-background !important;

  .top-bar {
    .widget-text {
      padding: 9px 7px;
      a {
        color: $font-color;

      }
    }
  }
}




    /***************************************
*  Home-4 info bar styles
****************************************/
.info-bar-four-wrapper{
  margin-top: 0px;
    border: none;
    background: $white;
    border-bottom: 1px solid #e5e5e5;
  .info-bar-four{

    .card-wrap{
      padding: 15px 0;
      .mat-card{
        height: 100px;
        box-shadow: none;
        border-right: 1px solid $grey6;
        box-shadow: none !important;
        background: transparent;
        .content {
          p {
            font-weight: 600;
          font-size: 14.5px;
          }
        }
        &.no-bd {
          border-right: none;
        }
    }
    .mat-icon{
      color: var(--theme-default);
      font-size: 41px;
      margin-right: 10px;
      line-height: 65px;
      clear: both;
      overflow: hidden;
      /* display: block; */
      width: auto;
      height: 100%;
      /* vertical-align: middle; */
      padding: 0 3px;
    }
      }
  }
 }

    /***************************************
*  Home-4 banner styles
****************************************/
.banners-four {
  padding: 25px 0;
  .banners-container {
    .four {
      height: 162px;

    }
  }
}
    /***************************************
*  Home-4 products styles
****************************************/

 .products {
  .products-four {
    h3 {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      position: relative;
      padding-bottom: 20px;
      &::before {
        content: "";
        left: 29%;
        position: absolute;
        width: 43%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: rgba(0,0,0,.08);
    }
    &::after {
      content: "";
    left: 48.5%;
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background-color: $dark-font;
    bottom: 0.3125rem;
  }
    }
  }
 }


 .featured-products-wrap {
  background: $background;
}
    /***************************************
*  Home-4 banner-promotion styles
****************************************/


.main-banner {

  .banner-pro {
    height: 442px;
    img {
      max-width:100%;
      height: auto;
    }
  }
}

    /***************************************
    ***************************************
    ***************************************
*  HOME - 5 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-5 styles
****************************************/




/***************************************
*  Menu-5 styles
****************************************/

.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  // padding: 0 13px !important;
    border-top: 4px solid var(--theme-default) !important;
  .mat-menu-item {
    border-bottom: 1px dashed $gray-lighter;
    span {
      background: var(--theme-default) !important;
      font-size: 11px;
      font-weight: 400;
      color: #fff;
      padding: 1px 3px;
    }
    .soon {
      background: #12b13b !important;
    }
  }
}
  .top-navbar a{
    padding: 15px 26px;
  }


  .menu-container5 {
   background: transparent;
    .container {
      background: transparent;
      a.mat-button {
        text-transform: uppercase;
        color: $font-color;
       font-size: 1em;
       font-weight: 700;
       padding: 0 25px;
       line-height: 63px;
      }
    }

  }

/***************************************
*  Categories menu styles
****************************************/
.categories-menu-wrap {
  .category-contant {
    ul {
      background-color: #403f3f;
    li {
      width: 14.285%;
      transition: .3s;
      a {
        display: flex;
        flex-direction: column;
    line-height: 16px;
    letter-spacing: -.13px;
    color: #fff;
    padding: 15px 16px 18px 16px;
    justify-content: center;
    text-align: center;
    transition: .3s;
    i {
      margin-bottom: 5px;
    }
    &:hover {
      color: $dark-font !important;
        }
      }
      &:hover {
    background: $grey3;
    color: $dark-font !important;
      }
    }
    }
  }
}
/***************************************
*  Products styles
****************************************/
.home-5-wrap {
  background: $white;
  .products {
    position: relative;
    h3 {
      margin-bottom: 0;
      display: inline-block;
      font-size: 22px;
      font-weight: 500;
      position: absolute;
      top: 69px;
    }
    .mat-tab-labels {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

/***************************************
*  Categories section styles
****************************************/

.home-5-wrap {
  .categories {
    background: $footer-background;
    .title {
      position: relative;
      margin-bottom: 15px;
    padding-bottom: 1.25rem;
      h3 {
        margin-bottom: 0;
      display: inline-block;
      font-size: 22px;
      font-weight: 500;
      &::before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: #e4e4e4;
      }
      &::after {
        content: "";
    left: 0;
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background-color: #222222;
    bottom: 0.3125rem;
      }
      }
    }

      .left-section {
        padding-right: 7px;
        .item {
          background: $white;
          height: 100%;
          padding: 20px;
          border: 1px solid $grey2;
          .product {
            text-align: center;
            img {
              max-width: 40%;
            }
          }
          .product-info {
            display: flex;
            flex-direction: row;
            justify-content: center;
            h4 {
              margin-right: 15px;
              a {
                color: $font-color;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            ul {
              margin-left: 15px;
              li {
                a {
                  color: $grey;
                  font-size: 15px;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
      .right-section {
        padding-left: 7px;
        .item {
          background: $white;
          height: 100%;
          padding: 20px;
          border: 1px solid $grey2;
          .product {
            text-align: center;
            img {
              max-width: 40%;
            }
          }
          .product-info {
            display: flex;
            flex-direction: row;
            justify-content: center;
            h4 {
              margin-right: 15px;
              a {
                color: $font-color;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            li {
              a {
                color: $grey;
                font-size: 15px;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
  }
}
    /***************************************
    ***************************************
    ***************************************
*  HOME - INDUSTRIAL STYLES
****************************************
****************************************
****************************************/
/***************************************


/***************************************
*  Home product section styles
****************************************/

.products-industrial {
  position: relative;

  .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
    pointer-events: auto;
}
  .product-wraper{
    padding: 20px 0px 20px 20px;
    .product-box {
      width: 100%;


    }
  }
//   .swiper-slide {
//  width: 245.2px !important;
//  margin-right: 16px;
//   }
.product-item-1 {

  .mat-card:not([class*=mat-elevation-z]) {

    .product-img {
      background: $white;

    }
  }
}
}





/* Categories banners */

.industrial-home-wrap {
  .categories {
    background: $white;
    .title {
      position: relative;
      margin-bottom: 15px;
    padding-bottom: 1.25rem;
      h3 {
        margin-bottom: 0;
      display: inline-block;
      font-size: 22px;
      font-weight: 500;
      &::before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: #e4e4e4;
      }
      &::after {
        content: "";
    left: 0;
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background-color: $grey;
    bottom: 0.3125rem;
      }
      }
    }

      .left-section {
        padding-right: 7px;
        .item {
          background: $baners-background;
          height: 100%;
          padding: 20px;
          border: 1px solid $grey2;
          .product {
            text-align: center;
            img {
              max-width: 40%;
            }
          }
          .product-info {
            display: flex;
            flex-direction: row;
            justify-content: center;
            h4 {
              margin-right: 15px;
              a {
                color: $font-color;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            ul {
              margin-left: 15px;
              li {
                a {
                  color: $grey;
                  font-size: 15px;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
      .right-section {
        padding-left: 7px;
        .item {
          background: $baners-background;
          height: 100%;
          padding: 20px;
          border: 1px solid $grey2;
          .product {
            text-align: center;
            img {
              max-width: 40%;
            }
          }
          .product-info {
            display: flex;
            flex-direction: row;
            justify-content: center;
            h4 {
              margin-right: 15px;
              a {
                color: $font-color;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            li {
              a {
                color: $grey;
                font-size: 15px;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .h {
        height: 205px !important;
      }
  }
}

/* Info bar */

.info-wrap-industrial {
  position: relative;
  background: #f7f7f7;
  .info-bar {
    .card-wrap {
      margin-bottom: 20px;
      .light-block {
        .icon {
          border: 2px solid #c3c3c3;
          width: 70px;
          height: 70px;
          text-align: center;
          line-height: 89px;
          .mat-icon {
            font-size: 31px;
            margin-right: 5px;
            color:  var(--theme-default) !important;
        }
      }
      .content {
        text-align: center;
        margin-top: 15px;
        p {
          font-weight: 600;
      }
      span {
        line-height: 26px;
      }
    }
      }
  }
  }
   .subscribe {
    padding: 50px;
    margin-top: 30px;
    background:  var(--theme-default) !important;
    .subscribe-wrap {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;
      p {
        color: #fff;
        font-size: 16px;
    }
      .form-wrap {
        margin-left: 20px;
         input {
          background: hsla(0,0%,100%,.2);
          width: 347px;
          border: none;
          color: #fff;
          padding: 2px 14px;
          height: 40px;
      }
         button {
          width: 175px;
          height: 44px;
          color: #fff;
          background: transparent!important;
          border: 2px solid #fff;
          border-radius: 0;
          margin-left: 15px;
      }
    }
  }
}
}


    /***************************************
    ***************************************
    ***************************************
*  HOME - FOOD STYLES
****************************************
****************************************
****************************************/



.topbar-food {
  background: var(--theme-default) !important;
  .top-bar {
    .widget-text {
      a {
        color: $white;
        text-decoration: none;
        font-size: 13px;
        transition: 0.4s;
        .mat-icon {
          color: $white !important;
        }
    }
    }
  }
}


/* Header six section */

.header-six {
  position: relative;
  .header-wrapper {
    position: absolute;
    top: 20px;
    z-index: 9999;
    left: 0;
    right: 0;
    .logo {
      cursor: pointer;
    }
    .main-menu {
      margin-left: -15px;
      a {
        text-transform: uppercase;
        color: $white;
      }
    }
  }
}

.mat-menu-content {
  width: 221px;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 4px solid $grey3 !important;
}

/* Product section */
.food-product-section {
  position: relative;
  top: -15px;
  background: url(/assets/images/background/download.svg) no-repeat 90%;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
  z-index: 989;
  .back-img1 {
    position: absolute;
    left: 0;
    top: 56px;
  }
  .back-img2 {
    position: absolute;
    left: 0;
    bottom: 56px;
  }
  .back-img3 {
    position: absolute;
    right: 0;
    bottom: 235px;
  }
  .head-title {
    text-align: center;
    h3 {
      text-align: center;
      margin-bottom: 15px;
    }
    p{
      line-height: 28px;
      margin-top: 0px;
    }
  }
  .prod-menu {
    margin-top: 45px;
    margin-bottom: 45px;
    ul {
      li {
        cursor: pointer;
        transition: .3s;
        .icon {
          height: 50px;
          svg {
            max-height: 38px;
            fill: $grey4 !important;
            .cls-1 {
              stroke: $grey4;
            }



          }
        }
        .text {
          p {
            color: $grey;
            margin: 0;
          }
        }
        &:hover {
          transition: .3s;
          .icon {
            svg {
              color: $theme-main;
            }
          }
          .text {
            p {
               transition: .3s;
              color: $theme-main;
            }
          }
        }
      }
    }
  }
  .product-wrapper {
    display: grid;
    // grid-template-rows: 150px 150px;
     grid-template-rows: repeat(2, auto);
     grid-template-columns: repeat(4, 1fr);
    // grid-template-columns: 150px 150px 150px;
     grid-row-gap: 20px;
     grid-column-gap: 20px;
    .product-box {


      .product-item {
        position: relative;
        overflow: hidden;

        // transition: all .5s ease;
        mat-card {
          padding: 0;
          box-shadow: none;
          border: 1px solid $grey6;
          .product-img {
            text-align: center;
            cursor: pointer;
            img {
            max-width: 180px;
            }
          }
          .product-info-wrapper {
            background: #f9f9f9;
            padding: 15px;
                .category {
                 text-align: left;
                 font-weight: 400;
                  color: $grey7;
                }
                .title-wrap {
                  margin: 7px 0;
                  h4 {
           font-size: 21px;
           margin: 0;
                  }
                  p {
                    font-size: 21px;
                    font-family: 'Gelasio', serif;
                    font-weight: 600;
                    margin: 0;
                  }
                }
                .stars {
                 .mat-icon {
                   font-size: 20px;
                 height: 22px;
                 width: 22px;
                  color: $theme-main;
                 }
                }
                .btn-wrap {
                  margin-top: 10px;
                  .view-more {
                    font-weight: 500;
                    color: $grey7;
                    cursor: pointer;
                    }
                    a {
                      cursor: pointer;
                      .mat-icon {
                        color: var(--theme-default) !important;
                      }

                    }
                  .bucket {
                   width: 38px;
                    height:38px;
                    border-radius: 50%;
                    background: #dedede;
                  text-align: center;
                   vertical-align: middle;
                   line-height: 49px;
                    //   box-shadow: none;
                    //   cursor: pointer;
                    a {

                      mat-icon {

                      }
                    }
                  }

                }
                .details-products {

                    .more-info {
                      margin-top: 15px;
                      overflow: hidden;
                      p {
                        line-height: 24px;
                      }
                    }
                }
                .icons {
                  position: absolute;
                  top: 30px;
                  right: -10px;
                  opacity: 0;
                  // transition: all .6s ease;
                  mat-icon {
                    color: $grey4;
                    cursor: pointer;
                  }
                }

          }
          &:hover {
            .icons {
              opacity: 1;
              transition: all .4s ease;
              transform: translateX(-20px);
             .ic-1 {
              animation-delay: 2s;
            }
            .ic-2  {
              animation: .5s ease-in-out fadeInRight;
            }
            .ic-3  {
              animation: .7s ease-in-out fadeInRight;
            }
            }
           }
        }

      }
    }
  }
}

/* Product details page */


.food-details-wrap {
  .header-title {
  background-size: cover;
  padding: 130px 0;
  position: relative;
    .title {
      text-align: center;
      h2 {
        color: $white;
        font-size: 52px;
        margin: 0;
      }
      p {
        color: $white;
        line-height: 26px;
      margin: 0;
      }
    }
  }
  .details-wrapper {

    position: relative;
    top: -15px;
    background-size: auto 75px;
    background-repeat: repeat-x;
    background-position: center 0;
    .product-image {
      box-shadow: none !important;
      border: 1px solid $grey8;
      border-radius: 0;
      img {
        max-width: 100%;
      }
      a {
        cursor: pointer;
      }
    }
    .product-right {
      padding: 0 30px 0 25px;
      .product-details {
        padding-bottom: 15px;
          border-bottom: 1px dashed $grey-light;
        h2 {
          margin-bottom: 10px;
        }
        h4 {
          del {
            color: $grey;
            font-size: 17px;
          }
          span {
            padding-left: 5px;
            color: $theme-main-red;
            font-size: 24px;
          }
        }
        .avalibility {
          padding-bottom: 15px;
          span {
            color: $theme-main-red;
            font-weight: 500;
          }
        }
        p.description {
          line-height: 26px;
        }
        h3.price {
          font-size: 26px;
          font-weight: 400;
          margin: 10px 0;
        }


      }
      .py-1 {
        .red {
          font-weight: 600;
          color:var(--theme-default);
        }
      }
      .quantity {
        margin-top: 10px;

        span {
          margin-bottom: 0;
          display: block;
          .mat-icon {
            font-weight: 300;
            font-size: 20px;
          }

        }

        .quantity-box {
          border: 1px solid $grey8;
          margin-right: 10px;
          input {
            width: 48px;
            text-align: center;
            background-color: #fff;
            height: 100%;
            border: none;
            border-left: 1px solid $grey8;
            border-right: 1px solid $grey8;
          }
          button {
            line-height: 44px;
          }
        }
      }
      .social-icons {
        padding-top: 15px;
    border-top: 1px dashed $grey-light;

        ul {
          li {
            width: 33px;
            height: 33px;
            border: 1px solid $grey3;
            border-radius: 50%;
            text-align: center;
            margin-right: 5px;
            a {
              text-decoration: none;
              font-size: 15px;
              line-height: 32px;
              color: $grey-light;
              i {
                color: $grey4;
              }
            }
          }
        }
      }
    }
    .info-bar {
      border: 1px solid $grey-light;
      padding: 0 20px;
      margin-top: 0px !important;
      .mat-icon{
        color: $font-color;
        font-size: 41px;
        margin-right: 15px;
        line-height: 23px;
      }
      .content {
        p {
          text-transform: capitalize;
          font-weight: 500;
        }
      }
      .mat-card {
        box-shadow: none !important;
        padding: 20px 0;
        border-bottom: 1px solid $grey-light;

     }
     .mat-card.no-border {
      border-bottom: none;
    }
    }
    .review-wrapper {
      padding-right: 30px;
      margin-top: 30px;
      .mat-tab-label {
        color: #333333 !important;
        height: 48px;
        padding: 0 24px;
        cursor: pointer;
        box-sizing: border-box;
        /* opacity: 0.1; */
        min-width: 33%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        position: relative;
        background: #f4f4f4;
        margin: 0 2px;
        .mat-tab-label-content {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          color: #333333 !important;
          font-family: "Gelasio", serif;
          font-weight: 600;
          font-size: 15px;
      }
    }
      .full-desc {
        padding: 20px;
        p {
          line-height: 24px;
        }
        ul {
          margin-top: 15px;
          padding-left: 20px;
          li {
            margin-bottom: 7px;
          }
        }
      }
      .Reviews {
        .reviews {
          .name {
            font-weight: 500;
           }
           p {
            line-height: 22px;
            white-space: normal;
          }
          .text {
            margin-top: 10px;
            }
            .mat-list-item {
              .mat-list-item-content {
                align-items: start !important;
              }
            }
        }

      }
      .writte-reviews {
        .mat-form-field {
          width: 100%;
         }
         .rating {
           h3 {
            font-size: 22px;
            margin-top: 20px;
           }
         }
         .btn-project {
          background: $theme-main;
          padding: 3px 32px;
          color: #ffffff;
          border: 2px solid $theme-main;
          border-radius: 0;
          transition: 0.3s;
          &:hover {
            background: $white;
            color: $theme-main;
            border: 2px solid $theme-main;
         }
      }
    }
    .products {
      padding-right: 30px;
      .title {
        margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;
      &::before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: #f6f6f6;
    }
    &::after {
      content: "";
      left: 0;
      position: absolute;
      width: 2.5rem;
      height: 0.125rem;
      background-color: #222222;
      bottom: 0.3125rem;
    }
      }
    }


  }

  .sale-widget {
    padding: 15px 20px;
    background: $grey4;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
    height: auto;
    .sale-widget1 {
      color: $white;
      font-size: 36px;
       font-weight: 600;
       display: block;
       letter-spacing: .5;
    }
    .sale-widget2 {
      color: $white;
      font-size: 18px;
       font-weight: 600;
       display: block;
       letter-spacing: .5;
    }
    .discount {
      display: inline-block;
      color: $white;
      font-size: 77px;
      font-weight: 600;
      position: relative;
      letter-spacing: .5;
    }
    .percent {
      display: inline-block;
      line-height: 31px;
      color: $white;
      span {
        display: block;
        font-size: 3.067em;
        font-weight: 500;
        color: $white;
      }
    }
    p {
      color: $white;
      line-height: 24px;
      font-weight: 300;
      font-size: 15px;
    }
  }
  .small-carousel {
    .swiper-slide {
      mat-card {
        img {
          max-width: 110px;
    margin-bottom: 12px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
        }
      }
    }
  }
  .small-carousel {
    position: relative;
    margin-top: 10px;
    .swiper-container {
      height: 180px;
      .swiper-slide {
        mat-card {
          max-height: 120px;
          min-height: 120px;
        }
      }
      .swiper-button-prev {
      position: absolute;
      top: 32%;
      left: 0px;;
      }
      .swiper-button-next {
        position: absolute;
      top: 32%;
      right: 0px;;
      }
    }


  }
  .product-item {
    position: relative;
    overflow: hidden;

    // transition: all .5s ease;
    mat-card {
      padding: 0;
      box-shadow: none;
      border: 1px solid $grey6;
      .product-img {
        text-align: center;
        cursor: pointer;
        img {
        max-width: 180px;
        }
      }
      .product-info-wrapper {
        background: #f9f9f9;
        padding: 15px;
            .category {
             text-align: left;
             font-weight: 400;
              color: $grey7;
            }
            .title-wrap {
              margin: 7px 0;
              h4 {
       font-size: 21px;
       margin: 0;
              }
              p {
                font-size: 21px;
                font-family: 'Gelasio', serif;
                font-weight: 600;
                margin: 0;
              }
            }
            .stars {
             .mat-icon {
               font-size: 20px;
             height: 22px;
             width: 22px;
              color: $theme-main;
             }
            }
            .btn-wrap {
              margin-top: 10px;
              .view-more {
              font-weight: 500;
              color: $grey7;
              cursor: pointer;
              }
              .bucket {
                background: #dedede;
                  width: 38px;
                  height:38px;
                  border-radius: 50%;
                  text-align: center;
                  vertical-align: middle;
                  line-height: 49px;
                  cursor: pointer;
                a {

                  mat-icon {

                   font-size: 21px;
                  }
                }
              }
            }
            .details-products {
              .more-info {
                margin-top: 15px;
                overflow: hidden;
              }
            }
            .icons {
              position: absolute;
              top: 30px;
              right: -10px;
              opacity: 0;
              // transition: all .6s ease;
              mat-icon {
                color: $grey4;
                cursor: pointer;
              }
            }

      }
      &:hover {
        .icons {
          opacity: 1;
          transition: all .4s ease;
          transform: translateX(-20px);
         .ic-1 {
          animation-delay: 2s;
        }
        .ic-2  {
          animation: .5s ease-in-out fadeInRight;
        }
        .ic-3  {
          animation: .7s ease-in-out fadeInRight;
        }
        }
       }
    }

  }
  .title {
    margin-bottom: 22px;
  }
}
.no-bg {
  background: transparent !important;
  .title {
    h4 {
      font-size: 22px;
    }
  }

}
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}


.mat-list-base .mat-list-item.mat-3-line, .mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/* Galery section*/

.popular-recipes-box {
  padding: 90px 0 0 0;

  background-size: cover;
    position: relative;
    .recipes-contain {
      .head-title {
        text-align: center;
        color: $white;
        margin-bottom: 60px;
        h3 {

          text-align: center;
    margin-bottom: 15px;
          color: $white;
        }
        p {
          line-height: 28px;
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
      .gallery {
        .gallery-section {
          grid-column: full-start / full-end;
          display: grid;
          /*grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(7, 5vw);*/
          grid-template: repeat(5, 5vw)/repeat(8, 1fr);
          grid-gap: 1.5rem;
          padding: 1.5rem;
          img {
            max-width: 100%;
            height: auto;
            -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    will-change: transform;
        }
        .gallery__item {
          overflow: hidden;

          &:nth-child(1) {
            overflow: hidden;
            grid-row: 1 / span 2;
            grid-column: 1 / span 2; }
            &:nth-child(2) {
              grid-row: 1 / span 3;
              grid-column: 3 / span 3; }
              &:nth-child(3) {
            grid-row: 1/span 2;
            grid-column: 8/8; }

            &:nth-child(4) {
              grid-row: 3 / span 3;
              grid-column: 1 / span 2; }
              &:nth-child(5) {
                grid-row: 4 / span 2;
                grid-column: 3 / span 2; }
                &:nth-child(6) {
              grid-row: 4/6;
              grid-column: 5/8;}
              &:nth-child(7) {
              grid-row: 1/4;
              grid-column: 6/span 2; }
              &:nth-child(8) {
                grid-row: 3 / span 3;
                grid-column: 8 / -1;
        }
         &:hover {
img {
  -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
}
         }

               }

          .gallery__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 0px;
        }
        }
      }
    }
}

/* About products section*/

.about-products {
  padding: 90px 0;
  background: $background;
  .about-products-wrap {
  .left {
   .text {
     text-align: center;
     padding: 0 50px;
   h3 {
    text-align: center;
    margin-bottom: 10px;
   }
   span {
    font-weight: 500;
    line-height: 26px;
    margin-top: 10px;
   }
   p {
    line-height: 26px;
   }
   .btn-project {
     margin-top: 20px;
   }
   }
  }
  .right {
   .img-wrap {
     img {
       max-width: 100%;
       height: auto;
     }
   }
  }
  }
}

    /***************************************
*  Widget three styles
****************************************/
.widgets-three {
  width: 30px;
  margin-left: 0;
  position: relative;
  padding-top: 10px;
   .bucket {
     .mat-button-wrapper {
      width: 30px;

      svg {
        max-height: 40px;
        fill: $white;
      }
      .mat-badge {
        margin-left: -10px;
      }
      .cart-count-wrapper {
       position: absolute;
       text-align: center;
       top: -3px;
       left: 37px;
       background: var(--theme-default);;
       height: 18px;
       width: 18px;
       line-height: 18px;
       border-radius: 50%;
       font-size: 11px;
       color: $white;
       font-weight: 700;
      }
     }
.top-cart {
  span {
    color: $white;
  }
}
   }

}
.spCard-main {
  width: 100%;
  .mat-menu-content {
    width: 239px !important;

}
  .spCard-dropdown {
    background-color: $white;
      padding: 15px 10px;
      max-width: 300px;
      z-index: 400;
      .card-list-title {
       padding: 0 0 10px 0;
      }
      h4 {
        padding-bottom: 10px;
      }
      .new-product {
        .product {
          img {
            width: 70px;
          }
        }
        .close-circle {
          flex: 1;
          a{
            cursor: pointer;
            color: $grey3;
            transition: .3s;
            float: right;

            i {
              font-size: 20px;
            }
          }
        }
        .item {
          padding: 10px 0;
          .product {
            margin-right: 7px;
            background: #ffffff;
            /* border-radius: 3px; */
            border: 1px solid #e7e7e7;
          }
          .desc {
            p {
              font-weight: 500;
             }
             span {
              color: $grey;
                font-size: 15px;
                margin-top: 5px;
                /* padding-top: 7px; */
                display: block;
            }
          }
        }
        .total {
          padding-top: 15px;
          p {
            font-size: 15px;
          }
          span {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
      .card-list-title {
        a{
          color: $grey4;
          font-size: 14px;
          font-weight: 500;
         }
         p{
          color: $grey4;
          font-size: 14px;
          font-weight: 500;
         }
      }

  .woo-message {
    text-align: center;
      padding: 0;
      margin: 1.5em 0 0 0;
  }
  }
}

/***************************************
*  Carousel Food styles
****************************************/
.main-slider-food{
  height: 760px;
  .sl-wrap {
    position: relative;
    height: 100%;
    .content{
      height: 100%;
      position: relative;
      z-index: 9;
      /* padding-left: 294px; */
      vertical-align: middle;
      position: relative;
      text-align: center;
      /* top: 58%; */
      button {
        background: var(--theme-default) !important;
        // color: $white;
        // border: 2px solid $white;
        border-radius: 0;
        padding: 5px 33px;
        width: 200px;
        margin: 0 auto;
        margin-top: 20px;
        span {
          font-size: 14px;
      font-weight: 700;
          text-transform: uppercase;
        }
      }
      h1{
        font-size: 48px;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 0;
        // color: $white;
    }

    p{
        font-size: 19px;
        text-align: center;
        color: $white;
        margin-bottom: 30px;
        font-weight: 400;
         width: 756px;
         margin: 0 auto;
         line-height: 35px;
    }
    }
  }

  .slide-item{
    height: 100%;
    background-size: cover;
    background-position: center;
  }

}


.swiper-pagination-bullet-active {
  background: $white !important;
}




      /* .mask{
          opacity: 0.6;
          width: 100%;
          height: 100%;
          position: absolute;
          overflow: hidden;
          z-index: 0;
          background-color: rgba(0,0,0,0.8);
      } */

  .swiper-lazy-preloader{
      top: 18%;
  }



.h-100 {
  height: 100%;
}


    /***************************************
    ***************************************
    ***************************************
*  HOME - FURNITURE STYLES
****************************************
****************************************
****************************************/
/*  Topbar styles */
.main-topbar-furniture {
  background: #f3f3f3 !important;
 .top-bar {
  .widget-text {
    a {
      color: $grey-dark;
      text-decoration: none;
      font-size: 13px;
      transition: .4s;
      .mat-icon {
        vertical-align: middle;
        margin-right: 1px !important;
        font-size: 23px;
      }
      &:hover {
        color: $theme-main;
      }
    }
    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
    .mat-button-wrapper {
      img {
        margin-right: 3px;
      }
    }
  }


   .welcome-message {
     p {
       color: $grey7;
       font-size: 13.5px;
       font-weight: 400;
     }
   }
   span {
     .mat-icon {
       color: $theme-main;
     }
   }
   .cur-icon {
     color: $grey7 !important;
   }
  }
  .company-email {
    p {
      font-size: 14px;
      color: #333;
    }
  }
}

/***************************************
*  Header-2 styles
****************************************/


.home-header-seven {
  background: $white;
  .logo {
    padding: 25px 0;
    margin-left: -15px;
  }
  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;
    .form-wrap{
      background-color: transparent;
        white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: $grey4;
      background: rgba(0,0,0,.0);
      input {
        border-style: none;
          border-width: 1px;
          border-radius: 70px;
          border: 1px solid $grey2;
          -webkit-transition: box-shadow .3s ease-out;
          transition: box-shadow .3s ease-out;
          height: 40px;
          width: 100%;
          max-width: 100%;
          padding: 0 0 0 12px;
          float: left;
          outline: none;
          box-shadow: none;
      }
      .btn-search {
        overflow: visible;
        position: absolute;
        border: 0;
        margin: 0;
        cursor: pointer;
        height: 100%;
        min-width: 50px;
        right: -15px;
        top: 0;
        padding: 0 35px;
        color: $white;
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -ms-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
        text-transform: uppercase;
        background-color: var(--theme-default) !important;
        border-radius: 0 3px 3px 0;
        -webkit-border-radius: 0 3px 3px 0;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none !important;
        -webkit-appearance: none;
        border-radius: 0 50px 50px 0;
      }
    }
  }
  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          /* color: #fff; */
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
         }
      }
      .info-text {
        p{
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
         }
         span{
          font-size: 20px;
            font-weight: 600;

         }
      }
    }
    .main-menu-wrapper-2 {
      .top-navbar {
        background: $white;
        padding: 0;
        .mat-button-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 22px;
          .top-cart {
            span {
              font-size: 15px;
            }
          }
        }
        .commerce-buttons {
          .mat-button-wrapper {
            .cart-count-wrapper{
              position: absolute;
              top: -10px;
              left: 30px;
              background: var(--theme-default) !important;
              color: $white;
              height: 18px;
              width: 18px;
              line-height: 18px;
              border-radius: 50%;
              font-size: 11px;
            }
          }
        }
       }
    }
  }
  .widget-text {
    a {
      color: $grey4;
      text-decoration: none;
      font-size: 13px;
      transition: .4s;
      .mat-icon {
        vertical-align: middle;
        margin-right: 1px !important;
        font-size: 23px;
      }
      &:hover {
        color: var(--theme-default) !important;
      }
    }
    &:not(:last-child) {
      margin: 0 16px 0 0;
    }
    .mat-button-wrapper {
      img {
        margin-right: 3px;
      }
    }
  }
  .user {
    .mat-icon {
      font-size: 26px !important;
    }
  }
}


/***************************************
*  Slider and menu styles
****************************************/
.carousel-furniture {
  .ctn-box {
    box-sizing: content-box !important;
    .main-slider {
      height: 503px;
      .sl-wrap {
        position: relative;
        height: 100%;
        .content {
          padding-left: 20px;
          padding-right: 400px;

            height: 100%;
            position: relative;
            z-index: 9;
            /* padding-left: 294px; */
            vertical-align: middle;
            position: relative;
            /* top: 58%; */
            transform: none;
        }
      }

    }
    .slider-categories {
      padding-right: 15px;
      .categories-wrap {
        border: 1px solid #D9D9D9;
        .title {
          background: #f5f5f5;
          border-bottom: 1px solid #dddddd;
          color: #444;
          padding: 15px 20px 15px 10px;
          font-weight: 500;
          font-size: 14.5px;
      }
        .category-contant {
          ul {
            li {
              display: block;
              a {
                padding-top: 12px;
        padding-bottom: 12px !important;
        border-top: 1px solid $grey8;
        margin: 0 10px -1px;
              }
              .no-brd {
                border-top: none;
              }
            }
          }
        }
        .btn-sale {
          margin: 15px 10px;
          button {
            padding: 5px 33px;
            width: 100%;
            box-shadow: none;
        }
        }
    }

    }
  }



}



.main-menu {
  margin-left: -15px;
  a.mat-button {
    text-transform: uppercase;
    color:$white;
    font-size: 1em;
    font-weight: 700;
    padding: 0 20px;
    line-height: 63px;
  }
}

/***************************************
*  Products section styles
****************************************/
.products-furniture.products {
    position: relative;
    h3 {
      margin-bottom: 0;
    display: inline-block;
    font-size: 23px;
    font-weight: 580;
    position: absolute;
    }
    .mat-tab-labels {
      display: flex;
    flex-direction: row;
    justify-content: flex-end;
    }

}


/***************************************
*  Banner section styles
****************************************/
.commercial-banner {
      .image-column {

        background-repeat: no-repeat 90%;
        background-size: cover;

        align-items: center; }
        .inner-column {
         h1 {
          text-transform: capitalize;
          font-size: 52px;
          margin-bottom: 20px;
         }
         button {
          padding: 5px 33px;
          width: 200px;
          box-shadow: none !important;
      }
        }
    @media only screen and (max-width: 50em) {
        .image-column {
            grid-column: 1 / -1;
            padding: 6rem; } }
            .story__content {
              background-color: #f9f7f6;
              grid-column: col-start 5 / full-end;
              padding: 6rem 8vw;
              /*
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    */
              display: grid;
              align-content: center;
              justify-items: start; }
          @media only screen and (max-width: 50em) {
              .story__content {
                  grid-column: 1 / -1;
                  grid-row: 5 / 6; } }

          .story__text {
              font-size: 1.5rem;
              font-style: italic;
              margin-bottom: 4rem; }



}

   /***************************************
    ***************************************
    ***************************************
*  HOME - DECOR STYLES
****************************************
****************************************
****************************************/


/***************************************
*  Header section styles
****************************************/

.header-decor {
  > div {
    width: 100%;
  background-color: #455a64;
  .widget-text {
    a {
      .mat-icon {
        vertical-align: middle;
        margin-right: 1px !important;
        font-size: 23px;
        color: $white;
    }
    }
  }
  .call-bucket-wrap {
    .main-menu-wrapper-2 {
      .top-navbar {
        background: transparent !important;
        .mat-icon {
          color: $white;
        }
        .top-cart {

          span {
            color: $white;
          }
        }
      }
    }
  }
  }


}

/***************************************
*  Carousel section styles
****************************************/
.carousel-home-decor {
  position: relative;
  .ctn-box {
    box-sizing: content-box !important;
    position: absolute;
    top: -64px;
    z-index: 9999;

  }

    .main-slider {
      height: 640px;
      .sl-wrap {
        position: relative;
        height: 100%;
        .content {
          padding-left: 21%;
          padding-right: 400px;

            height: 100%;
            position: relative;
            z-index: 9;
            /* padding-left: 294px; */
            vertical-align: middle;
            position: relative;
            /* top: 58%; */
            transform: none;
        }
      }

    }
  .slider-categories {
    background-color: #fff;
  .categories-wrap {
    border: 1px solid $grey8;
    .title {
      background: #f5f5f5;
      border-bottom: 1px solid #dddddd;
      color: #444;
      padding: 19px 20px 19px 10px;
      font-weight: 500;
      font-size: 14.5px;

    .mat-icon {
      margin-right: 4px;
    }

    }
    .category-contant {
      ul {
        li {
          display: block;
          a {
            padding-top: 12px;
    padding-bottom: 12px !important;
    border-top: 1px solid $grey8;
    margin: 0 10px -1px;
          }
          .no-brd {
            border-top: none;
          }
        }
      }
    }
    .btn-sale {
      margin: 15px 10px;
      button {
        padding: 5px 33px;
        width: 100%;
        box-shadow: none;
      }
    }
  }


}
.carousel-2-wrapper {
  max-width: 100%;
  position: relative;
  .main-slider {
    height: 640px;
    .content {
      padding-left: 23%;
      padding-right: 485px;
    }
  }
}
.decor-menu {
  .main-menu {
    margin-left: 293px;
}
}
}

/***************************************
*  Home baners section styles
****************************************/
.banners-decor {
  margin-top: -70px;
    z-index: 9999999999999;
    position: relative;
    background: #fff;
    border: 1px solid #e6e6e6;
  .baners {
    background: $baners-background !important;
  }

  .baner {
  padding: 10px 0 0 10px;
    height: 100%;
    transition: .15s ease-in;
    text-align: left;
    h4{
      font-size: 19px;
      font-weight: 500;
      color: $grey-dark;
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    .info {
      .big-price {
      padding: 10px 25px !important;
      box-shadow: none !important;
      width: 135px;
          border-radius: 5px;
          text-align: center;
          span {
            font-size: 27px !important;
          }
      }
    }
    p{
      color: $theme-main-dark
    }
    a{
      font-size: 16px;
      font-weight: 700;
     text-decoration: none;
     color: $theme-main-dark;
     margin-top: 15px;
     display: block;
    }
    ul {
      li {
        a {
          font-size: 14px;
          font-weight: 400;
         text-decoration: none;
         color: #6b6b6b;
         margin-top: 5px;
         display: block;
        }
      }
    }
  }
  .first {
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    .info {
      .text-box {
        text-align: center;
        padding: 15px 20px;
        span {
          color: #fff;
          margin-bottom: 10px;
          line-height: 31px;
        }
        h4 {
          color: #fff;
          line-height: 27px;
        }
        a {
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
  .seccond {
    /* height: 200px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px;

  }
  .media {
    border-right: 1px solid $grey8;
    border-bottom: 1px solid $grey8;
}
.electrics {
  border-left: 1px solid $grey8;
}

.decors {
  border-bottom: 1px solid $grey8;
}
}


/***************************************
*  Commercial banners section styles
****************************************/
.commercial-banners {
  .image-column {
    background: #f6f6f6;
    padding: 0px 30px;
    h3 {
      margin-bottom: 20px;
    }
    span {
      color: #bc0911;
      font-weight: 500 ;
    }
    a {
      color: $font-color;
      font-weight: 500;
      text-decoration: underline;
    }
    .img {
      img {
        max-width: 85% ;
      }
    }

  }
}






/***************************************
*  Home footer-2 section styles
****************************************/
.footer-two {
  background-color: $theme-main-dark;

  .footer-widget {
    padding-right: 15px;
    .social {
      margin-top: 20px;
      li {
        width: 33px;
        height: 33px;
        border: 1px solid $grey3;
        border-radius: 50%;
        text-align: center;
        margin-right: 5px;
        a {
              color: $grey-light;
          i {
            color: $theme-main;
          }
        }
        .mat-icon-button {
          padding: 0;
          min-width: 0;
          width: auto;
          height: auto;
          flex-shrink: 0;
          line-height: 32px;
          border-radius: 50%;
          i {
            color:$white-1;
          }
      }
      }
    }
    .ped-btn-footer {
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 20px;
      padding: 0px 10px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.4s ease 0s;
      z-index: 222;
      background: var(--theme-default) !important;
      box-shadow: none !important;
      width: 100%;
      border-radius: 3px;
      line-height: 45px;
    }
    .title {
      margin-bottom: 10px;
      h5 {
        font-size: 18px;
        font-weight: 600;
        color: $white;
      }
      }

      a {
        text-decoration: none;
        font-size: 15px;
        line-height: 32px;
        color: $grey-light;
      }


        .newsletter-widget {
          position: relative;
          margin: 15px 0 20px 0;
        }

        p {
          line-height: 24px;
          color: $grey-light;
        }
        ul {
          list-style-type: none;
        }
        .about-widget {
          .logo {
            img {
              max-width: 240px;
            }
          }
        }
        .tel-box {
          .icon {
            i {
              display: block;
              line-height: 27px;
              vertical-align: middle;
              margin-right: 5px;
              color: var(--theme-default) !important;
              width: 18px;
              text-align: center;
              font-size: 18px;
            }
          }
          .text {
            p {
              a {
                line-height: 22px;
              }

            }
          }
        }
        .mat-icon-button {
          padding: 0;
          min-width: 0;
          width: auto;
          height: auto;
          flex-shrink: 0;
          line-height: 32px;
          border-radius: 50%;
      }
  }

  .subscribe-widget {
    input {
      height: 45px;
      background: $white;
      font-size: 15px;
      line-height: 45px;
      border: 1px solid $white-dark;
      border-radius: 4px;
      padding: 0 65px 0 20px;
      color: $grey;
    }
  }
}








/***************************************
*  Popular product page styles
****************************************/

.popular-products {
  .reviews {
    .content {
      height: auto;
     margin-bottom: 10px;
     margin-left: -15px !important;
      .media {
        margin-right: 7px;
        background: $gray-lighter;
        border-radius: 3px;
        img {
          max-width: 80px;
          margin-right: 8px;
        }
      }
      .info {
        h5  {
          color: rgba(0,0,0,.87);
          text-decoration: none;
          font-size: 17px;
          font-weight: 600;
          -webkit-transition: color .4s ease;
          transition: color .4s ease;
        }
        .price {
          font-weight: 400;
        }
      }
    }
  }
  .title-header {
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 1.25rem;
    h4 {
      &::before {
        content: "";
        left: 0;
        position: absolute;
      width: 100%;
      height: 0.0625rem;
      bottom: 0.34375rem;
      background-color: $grey6;
      }
      &::after {
        content: "";
        left: 0;
        position: absolute;
      width: 2.5rem;
      height: 0.125rem;
      background-color: $font-color;
      bottom: 0.3125rem;
      }
    }
  }

}





/***************************************
*  Login page styles
****************************************/
.login {
  .login-wrap {
    .title {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;
       > h2 {

       }
       &:before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: $grey6;
       }
       &:after {
        content: "";
    left: 0;
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background-color: $font-color;
    bottom: 0.3125rem;
       }
    }
    form {
      .mat-form-field {
        width: 100%;
        margin-bottom: 10px;
      }

    }
    .register-wrapper {
      .register-body {
        .mat-form-field {
          width: 100%;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        p {
          font-size: 15px;
      font-weight: 400;
      line-height: 26px;
        }
        .button-wrap {
          margin-top: 20px;
        }
      }

    }
  }




}

/***************************************
*  Price slider styles
****************************************/

    .price-slider {
      .filter-price {
        .mat-slider-horizontal {
          min-width: 110px;
        }
      }
    }

/***************************************
*  Product dialog styles
****************************************/

.product-dialog {
  .mat-dialog-container {
    overflow: visible;
  }
  .swiper-container {
    .swiper-wrapper {
      img.img-fluid {
        margin: auto;
        max-width: 100%;
      }
    }
  }
  .product-right {
    padding: 0 30px;
    .product-details {
      .discription {
        padding-top: 15px;
        padding-bottom: 20px;
        border-top: 1px dashed $grey-light;
        border-bottom: 1px dashed $grey-light;

          .bold {
            font-weight: 600;
          }
          p.productDescription {
            line-height: 24px;
            margin-top: 5px;
          }

      }
      h4 {
        span {
          padding-left: 5px;
          color: $theme-main-red;
          font-size: 25px;
        }
        del {
          color: $grey;
          font-size: 18px;
        }
      }
      h3.price {
        font-size: 26px;
        font-weight: 400;
        margin: 10px 0;
      }
    }
    .quantity {
      input {
        width: 80px;
        text-align: center;
        background-color: $gray-lighter;
        height: 35px;
        border: 1px solid $grey3;
      }
      span {
        margin-bottom: 5px;
       }
    }
    .avalibility {
      .red {
        color: $theme-main-red;
        font-weight: 500;
      }
    }
    .buttons {
      .mat-icon {
        color: $grey7;
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }
  .mat-dialog-container {
    .close-btn-outer button {
      position: absolute;
      right: -44px;
      top: -44px;
    }
  }
  .product-dialog {
    .close-btn-outer {
      position: relative;
    }
  }
}



.product-pagination .ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
  padding: 10px 5px;

}
.product-pagination .ngx-pagination .current {
  padding: 4px 12px;
  background: $grey2;
  color: $grey4;
  cursor: default;
  border-radius: 2px;
  line-height: 22px;
}

.product-dialog .mat-dialog-content {
  max-width: 52vw;
}


/***************************************
*  Popular products styles
****************************************/
.popular-products {

    .reviews {
      .stars {
        color: $theme-main;
        margin: 0px 0 5px 0;
        span {
          color: $grey3;
          font-size: 13px;
          margin-left: 10px;
        }
        mat-icon{
          font-size: 17px;
            line-height: 17px;
            height: 17px;
            width: 17px;
            vertical-align: middle;
        }
      }
    }

}
.mega-menu-pane {
  .mat-menu-panel{
    width: auto !important;
    .mat-menu-content {
      width: 100% !important;
    }
  }
}

/***************************************
*  Error page styles
****************************************/
.error {
  text-align: center;
  .page404 {
    font-size: 67px;
    font-weight: 700;
    color: $grey;
   margin-bottom: 30px;
  }
  .not-found {
    font-size: 39px;
    font-weight: 700;
    color: $font-color;
    margin-bottom: 30px;
  }
  .error_description {
    margin-bottom: 30px;
    p {
      font-weight: 600;
    }
  }
  button {
    background: $theme-main;
    color: $font-color;
    padding: 5px 33px;
    width: 200px;
  }
}
/***************************************
*  FAQ page styles
****************************************/

.faq {
  .questions {
    padding-right: 15px;
    .faq-wrap {
      .mat-expansion-panel {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border: 1px solid $grey-light;
        box-shadow: none;
        margin-bottom: 20px;
        box-shadow: none !important;
        .mat-expansion-panel-header-title {
          span {
            font-size: 17px;
            text-transform: capitalize;
            font-weight: 500;
            line-height: 1;
        }
        }
        .mat-expansion-panel-body {
          p {
            line-height: 24px;
          }
        }
    }
    }
  }
  .faq-form {
    padding-left: 15px;
   .text-wrap {
     p {
       line-height: 26px;
     }
   }
   form {
     margin: 30px 0;
   }
  }
}
/***************************************
*  About us page styles
****************************************/

.about-us-wrapper {
  .about-section {
    .left-info-panel {
      overflow: hidden;
      .about-us-intro {
        h2 {
          margin-bottom: 20px;
        }
        p {
          line-height: 26px;
          }
          .accordions {
            margin-top: 20px;
            .mat-expansion-panel {
             margin-bottom: 10px;
             box-shadow: none;
             border: 1px solid $grey6;
             border-radius: 5px;
             h4 {
               font-weight: 500;
             }
            }
          }
      }
    }

  }
  .our-values {
    background: $baners-background;

    .box-wraper {
      .box {
        padding-left: 15px;
        padding-right: 15px;
       .content {
        padding: 41px 20px 31px;
        background: $white;
        .icon-box {
          i {
            font-size: 55px;
            color: var(--theme-default);
          }
        }
        h4 {
          font-weight: 500;
          font-size: 22px;
          margin-bottom: 5px;
          margin-bottom: 10px;
        }
        p {
          line-height: 26px;
        }
       }
      }
    }

  }
  .title-wrapper {
    text-align: center;
    margin-bottom: 35px;
    h2 {
      margin-bottom: 10px;
    }
    p {
      color: $grey;
    }
  }
  .our-team {
    .team-members {
      .item {
        padding-left: 15px;
        padding-right: 15px;
            .single-team-member {
              transition: .3s;

              .img-box {
                position: relative;
                img {
                  width: 100%;
        -webkit-transition: opacity .4s ease;
        transition: opacity .4s ease;

                }
                .overlay {
                  position: absolute;
                  top: 0;
                  left: 0;
                   right: 0;
                   bottom: 0;
                  .box {
                    display: table;
                    width: 100%;
                    height: 100%;
                    .content {
                      display: table-cell;
                      vertical-align: bottom;
                      overflow: hidden;
                      ul {
                        background: var(--theme-default);
                        margin: 0;
                        margin-left: -12px;
                        margin-right: -12px;
                        -webkit-transform: translate3d(0, 35px, 0);
                         transform: translate3d(0, 35px, 0);
                         -webkit-transition: -webkit-transform .4s ease, visibility .4s ease, opacity .4s ease;
                         transition: transform .4s ease, visibility .4s ease, opacity .4s ease;
                         visibility: hidden;
                         li {
                          padding: 0 12px;
                           i {
                            color: $white;
                            font-size: 16px;
                            line-height: 35px;
                            display: block;
                           }
                         }
                      }
                    }
                  }
                }
              }

              .text-box {
                border: 1px solid $gray-lighter;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        -webkit-transition: .4s ease;
        transition: .4s ease;
              }
              &:hover {
                .img-box {
                  img {
                    opacity: .4;
                  }
                  .overlay {
                    .box {
                      .content {
                        .social {
                          opacity: 1;
                         visibility: visible;
                        -webkit-transform: translate3d(0, 0px, 0);
                        transform: translate3d(0, 0px, 0);
                        }
                      }
                    }
                   }
                }
                .text-box {
                  background: $grey4;
                  h4 {
                    color: $white;
                  }
                  p {
                    color: $white;
                  }
                }
            }



      }



    }
  }
}


}


/***************************************
*  Order success page styles
****************************************/
.succesfull-message {
  text-align: center;
  padding: 50px 0;
  .material-icons {
    color: #4CAF50;
    font-size: 63px;
  }
  h2 {
    margin: 10px 0;
}
  p {
    line-height: 30px;
}
}
.order-success {
  .billing-details {
    h3 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .product-order-details {
      .order-img {
         img {
           max-width: 130px;
         }
      }
      p {
        font-size: 17px;
        font-weight: 500;
    }
    span {
      font-size: 17px;
      margin-top: 5px;
    }
    }
    ul.price-list {
      li {
        padding: 10px 0;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        p {
          line-height: 30px;
          font-weight: 500;
      }
      span {
        position: relative;
        width: 35%;
        float: right;
        font-size: 18px;
        text-align: right;
        color: #4c4c4c;
      }
      img {
        max-width: 30px;
    height: 23px;
    margin: 0 10px;
      }
    }
    }
  }
  .mainOrder-wrapper {
    .main-order {
      background: #f3f3f3;
      padding: 30px;
      padding: 30px;
      .order-box {
        .title-box {
          position: relative;
          padding-bottom: 25px;
          color: $grey4;
          font-weight: 500;
          font-size: 20px;
          border-bottom: 1px solid $grey6;
          margin-bottom: 20px;
          span.tl-amount {
            position: relative;
            width: 35%;
            float: right;
            line-height: 1.2em;
          }
        }
        ul.price-list {
          position: relative;
          border-bottom: 1px solid $grey6;
          margin-bottom: 20px;
          padding-bottom: 20px;
          li {
            padding: 10px 0;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            p {
              line-height: 30px;
              font-weight: 500;
            }
            span {
              position: relative;
              width: 35%;
              float: right;
              font-size: 18px;
              text-align: right;
              color: #4c4c4c;

            }
          }
        }
      }
      ul.total-amount {
        position: relative;
        border-bottom: 1px solid $grey6;
        margin-bottom: 30px;
        padding-bottom: 20px;
        .shipping {
          width: 35%;
          float: right;
          .shopping-option {
            line-height: 30px;
          }
        }
        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: $font-color;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;
          span.amount{
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: $theme-main-red;
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }
      ul.quantity {
        position: relative;
        border-bottom: 1px solid $grey6;
        margin-bottom: 30px;
        padding-bottom: 20px;
        li {
          span.total{
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: $theme-main-red;
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }
      ul.total {
        position: relative;

        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: $font-color;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;
          span.count {
            position: relative;
              font-size: 20px;
              line-height: 20px;
              color: $theme-main-red;
              font-weight: 400;
              width: 35%;
              float: right;
              text-align: right;
          }
        }
      }
      .paymentFinal {
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}

/***************************************
*  Breadcrumb  styles
****************************************/
.breadcrumb-section {
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
  .breadcrumb-title {
    h4 {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .breadcrumb-path {
    .breadcrumb {
      background-color: transparent;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 14px;
    color: #555;
    font-weight: 500;
    margin: 0;
    padding-right: 0;
    .breadcrumb-item {
      padding: 0 .5rem;
      &:active {
        color: #6c757d;
    }
    }
    a {
      color: #555;
    }
    }

  }
}

/***************************************
*  Checkout page styles
****************************************/

.checkout {
  .billing-details {
    .mat-form-field {
      width: 100%;
    }
    .add-info {
      margin-top: 15px;
      }
  }


  .mainOrder-wrapper {
    .main-order {
      border: 1px solid $grey8;
      padding: 30px;
      .order-box {
        .title-box {
          position: relative;
          padding-bottom: 25px;
          color: $grey4;
          font-weight: 500;
          font-size: 20px;
          border-bottom: 1px solid $grey6;
          margin-bottom: 20px;
          span.tl-amount {
            position: relative;
            width: 35%;
            float: right;
            line-height: 1.2em;
          }
        }
        ul.price-list {
          position: relative;
          border-bottom: 1px solid $grey6;
          margin-bottom: 30px;
          padding-bottom: 20px;
          li {
            line-height: 44px;
            span {
              position: relative;
              width: 35%;
              float: right;
              font-size: 20px;

            }
          }
        }
      }
      ul.total-amount {
        position: relative;
        border-bottom: 1px solid $grey6;
        margin-bottom: 30px;
        padding-bottom: 20px;
        .shipping {
          width: 35%;
          float: right;
          .shopping-option {
            line-height: 30px;
          }
        }
        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: $font-color;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;
          span.amount{
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: $theme-main-red;
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }
      ul.quantity {
        position: relative;
        border-bottom: 1px solid $grey6;
        margin-bottom: 30px;
        padding-bottom: 20px;
        li {
          span.total{
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: $theme-main-red;
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }
      ul.total {
        position: relative;
        border-bottom: 1px solid $grey6;
        margin-bottom: 30px;
        padding-bottom: 20px;
        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: $font-color;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;
          span.count {
            position: relative;
              font-size: 20px;
              line-height: 20px;
              color: $theme-main-red;
              font-weight: 400;
              width: 35%;
              float: right;
          }
        }
      }
      .paymentFinal {
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}









.mat-radio-label-content {
color: $grey5;
font-weight: 400;
}




/***************************************
*  Cart page styles
****************************************/
.cart-wrapper {
  .mat-card {
    text-align: center;
    .cart-table.mat-table {
      display: block;
      overflow-x:auto;
      .mat-row{
        min-height: 100px;
        min-height: 100px;
  border-bottom: 1px solid $grey2;
        .product-name{
          color: inherit;
          text-decoration: none;
          font-weight: 500;
      }
      .grand-total{
        font-weight: 500;

    }
    span:nth-child(3){
      font-size: 16px;
  }

  input {
    width: 80px;
    text-align: center;
    background-color: $grey2;
    height: 35px;
    border: 1px solid $grey6;
  }
  .price {
    font-size: 21px;
      font-weight: 500;
  }
  .total {
    font-size: 21px;
      font-weight: 500;
      color: $theme-main-red;
  }
    }
    .mat-cell, .mat-header-cell {
      flex: 1;
      overflow: hidden;
      word-wrap: break-word;
      font-size: 14px;
      img{
        width: 100px;
        cursor: pointer;
    }
    mat-icon {
      cursor: pointer;
    }
  }
    }
    }

}

.mat-row, .mat-header-row {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  min-height: 48px;
  padding: 0 24px;
  min-width: 870px;
}


/***************************************
*  Compare page styles
****************************************/
.compare-component {
  .table-responsive {
    display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
    table {
      width: 100%;
      border: 1px solid $grey2;
      thead {
        .th-compare {
          td {
            padding: 15px;
            vertical-align: top;
            border-bottom: 1px solid $grey2;
            border-top: 1px solid $grey2;
            border-right: 1px solid $grey2;
            background: $gray-lighter;
            font-weight: 600;
              font-size: 14px;
          }
          a {
            cursor: pointer;
          }
        }
      }
      tbody {
        tr {
          th {
            padding: 15px;
            vertical-align: top;
            border-bottom: 1px solid $grey2;
            border-top: 1px solid $grey2;
            border-right: 1px solid $grey2;
            background: $gray-lighter;
            text-align: left;
          }
          .product-name {
            width: 15%;
            font-size: 14px;
          }
          td {
            .description-compare {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
  img.img-fluid.mb-4 {
    max-width: 100px;
  }
  .empty-cart-cls {
    h4 {
      font-weight: 500;
      color: $grey5;
    }
    h3 {
      text-align: center;
      }
  }
}


.compare-component .table-responsive .table tbody tr td, .compare-component .table-responsive .table tbody tr th {
  padding: 15px;
  vertical-align: top;
  border-top: 1px solid $grey2;
}



/***************************************
*  Details page styles
****************************************/
.details-wrapper {
  .product-image {
    img {
      max-width: 100%;
    }
  }
  .product-right {
    padding: 0 30px 0 25px;
    .product-details {
      padding-bottom: 15px;
        border-bottom: 1px dashed $grey-light;
      h2 {
        margin-bottom: 10px;
      }
      h4 {
        del {
          color: $grey;
          font-size: 17px;
        }
        span {
          padding-left: 5px;
          color: $theme-main-red;
          font-size: 24px;
        }
      }
      .avalibility {
        span {
          color: $theme-main-red;
          font-weight: 500;
        }

      }
      p.description {
        line-height: 26px;
      }
      h3.price {
        font-size: 26px;
        font-weight: 400;
        margin: 10px 0;
      }


    }
    .quantity {
      margin-top: 10px;

      span {
        margin-bottom: 7px;
        display: block;
      }
      input {
        width: 50px;
        text-align: center;
        background-color: #EEEEEE;
        height: 100%;
        border: 1px solid $grey3;
      }
    }
    .red {
      color: #f33927;
      font-weight: 700;
    }
  }
  .info-bar {
    border: 1px solid $grey-light;
    padding: 0 20px;
    margin-top: 0px !important;
    .mat-icon{
      color: $font-color;
      font-size: 41px;
      margin-right: 15px;
      line-height: 23px;
    }
    .content {
      p {
        text-transform: capitalize;
        font-weight: 500;
      }
    }
    .mat-card {
      box-shadow: none !important;
      padding: 20px 0;
      border-bottom: 1px solid $grey-light;

   }
   .mat-card.no-border {
    border-bottom: none;
  }
  }
  .review-wrapper {
    // padding-right: 30px;
    // margin-top: 30px;
    .full-desc {
      padding: 20px;
      p {
        line-height: 26px;
      }
      ul {
        margin-top: 15px;
        padding-left: 20px;
        li {
          margin-bottom: 7px;
        }
      }
    }
    .Reviews {
      .reviews {
        .name {
          font-weight: 500;
         }
         p {
          line-height: 22px;
          white-space: normal;
        }
        .text {
          margin-top: 10px;
          }
          .mat-list-item {
            .mat-list-item-content {
              align-items: start !important;
            }
          }
      }
      .writte-reviews {
        .mat-form-field {
          width: 100%;
         }
      }
    }

  }
  .products {
    padding-right: 30px;
    .title {
      margin-bottom: 15px;
    position: relative;
    padding-bottom: 1.25rem;
    &::before {
      content: "";
      left: 0;
      position: absolute;
      width: 100%;
      height: 0.0625rem;
      bottom: 0.34375rem;
      background-color: #f6f6f6;
  }
  &::after {
    content: "";
    left: 0;
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background-color: #222222;
    bottom: 0.3125rem;
  }
    }
  }

    .sale-widget {
      height: 14%;
    margin-top: 25px;
      padding: 15px 20px;
      text-align: center;
      background-color: var(--theme-default);
      .sale-widget1 {
        color: $white;
        font-size: 36px;
         font-weight: 600;
         display: block;
         letter-spacing: .5;
      }
      .sale-widget2 {
        color: $white;
        font-size: 18px;
         font-weight: 600;
         display: block;
         letter-spacing: .5;
      }
      .discount {
        display: inline-block;
        color: $grey-dark;
        font-size: 77px;
        font-weight: 600;
        position: relative;
        letter-spacing: .5;
      }
      .percent {
        display: inline-block;
        line-height: 31px;
        span {
          display: block;
          font-size: 3.111em;
          font-weight: 500;
        }
      }
      p {
        color: $white;
        line-height: 24px;
        font-weight: 300;
        font-size: 15px;
      }
    }


  .small-carousel {
    position: relative;
    margin-top: 10px;
    .swiper-container {
      height: 180px;
      @media only screen and (max-width: 600px) {
        height: 190px;
      }
      .swiper-slide {
        mat-card {
          padding: 0px;
          max-width: 100%;
          max-height: 100%;
          img {
            max-width: 100%;
            max-height: 100%;
            margin-bottom: 12px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
          }
        }
      }
      .swiper-button-prev {
      position: absolute;
      top: 32%;
      left: 0px;;
      }
      .swiper-button-next {
        position: absolute;
      top: 32%;
      right: 0px;;
      }
    }


  }
  .sale-baner {
background: transparent;
  }
  .mat-expansion-panel {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid #dddddd;
    box-shadow: none !important;
    margin-bottom: 20px;
    background: transparent !important;
}
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}


.mat-list-base .mat-list-item.mat-3-line, .mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}


/***************************************
*  Brands page styles
****************************************/

.brands-items {
  .mat-radio-button {
    margin-bottom: 15px;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background: $theme-main-red !important;
  }
}


/***************************************
*  Categorie page styles
****************************************/

.category-contant {
  ul {
    li {
      a {
        color: $grey;
        padding-bottom: 10px;
        display: block;
        font-size: 15px;
      }
    }
  }
}

/***************************************
*  Contact us page styles
****************************************/

.contact-page {
  iframe {
    width: 100%;
    height: 400px;
    border: none;
}
  .left-info-panel {
    // padding-right: 100px;
    ul {
      list-style-type: none;
      padding: 0;
      > *:not(:last-child) {
        border-bottom: 1px solid $grey6;
        padding-bottom: 20px;
      }
      li {
        i {
          float: left;
          font-size: 33px;
          line-height: 74px;
          margin-right: 7px;
          color: var(--theme-default);
        }
        h4{
          display: inline-block;
            font-size: 20px;
        }
      }
    }
  }
  .contact-form {
    .title {
      margin-bottom: 20px;
    }
    .w-100 {
      width: 100% !important;
    }
    .mat-form-field {
      caret-color: var(--theme-default) !important;
      font-size: 14px !important;

      .mat-input-element {
        caret-color: var(--theme-default) !important;
      }
    }
    .px-1 {
      margin-bottom: 15px;
      }
  }
}


@media screen and (max-width: 599px) {
  .contact-page {
    .left-info-panel {
      padding-right: 0px;
      width: 100%;
    }
  }
}
.sidenav  {
  .divider {
margin: 10px 0;
  }

}
:host {
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;

  .mat-list-item.active {
    background-color: mat-color($theme-main, 50);
  }
  &:hover,
  &:focus {
    >.mat-list-item:not(.expanded) {
      background-color: mat-color($theme-main, 100) !important;
    }
  }
}

.mat-list-item {
  padding: 10px 0;
  display: flex;
  width: auto;
  .mat-list-item-content {
    width: 100%;
  }

  .routeIcon {
    margin-right: 40px;
  }
}



.btn-default,.mat-button{
  color:$font-color;
}
.header.mat-toolbar {
  box-shadow: 0 0 5px grey;
  z-index: 13;
  font-size: 20px;
  max-height: 64px;
  height: 64px;
  padding: 0px 10px;
}

.sub-container .mat-card {box-shadow: none !important;}
.search_box-n {display: flex;
  flex-wrap: wrap;
  justify-content: space-between;}
  .search_box-n .search_input {width: 85% !important;}
  .search_box-n .search_input input {height: 40px !important;}
  table.mat-elevation-z8 thead tr.b-b.ng-star-inserted {display: table-row !important;}
  table.mat-elevation-z8 tbody tr.b-b.ng-star-inserted {display: table-row !important;}
  .mat-dialog-content {width: 100%;}
  .example-form {max-width: 100% !important;}
  .mat-dialog-container {position: relative !important;}
  .mat-dialog-container .close-button {top: -25px !important; right: -25px !important; position: absolute !important; }
 .edb-space {margin-top: 10px;}

 .mat-icon.mat-warn {color: #ffa500 !important;}
.vl {
    margin: unset;
    border-left: 1px solid #000;
    height: 30px; margin-top: 15px;
}
.c-info {text-align: center;}
.c-info h3 {text-align: center;}
.c-info a { background: none !important; }
.c-info a:hover { background: none !important; }
.c-info  .mat-toolbar  { background: none !important; }
.c-info  .mat-toolbar a i {width: 40px;
  height: 40px;
  background: #333;
  border-radius: 500px;
  font-size: 20px !important;
  line-height: 40px !important;
  color: #FFF !important;}
.home-header .top-navbar .mat-button-wrapper {text-transform: uppercase;}



 .invoiceDialog {
  .mat-dialog-container {
      margin: 20px 0px;
      padding: 0px;
  }
}

.exigo-summary-cart {

  ::ng-deep .mat-drawer{
    width: 420px;
    padding:0px;
  }
  ::ng-deep .mat-icon-button{
    padding:8px;
  }
}

.mat-badge-content {
  background-color: #ff8188;
}
.cdk-global-overlay-wrapper{
  pointer-events: auto;
  overflow: auto;
}

@media (max-width:500px) {
  .mat-form-field-infix {
    width: 100px !important;
  }
}

.first-mobile-flex .mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}

.rain-cash-wallet-dialog .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #dfdbdb;
  background: #dfdbdb;
}
.white-space-pre-wrap {
  white-space: pre-wrap !important;
}
#RainPointsPayments .mat-checkbox-inner-container {
  margin-top: 3px !important;
}