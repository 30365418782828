/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

// Color 1 scss
@import "./assets/scss/color1.scss";
@import "./assets/scss/theme/theme";
@import "./assets/scss/custom.scss";

@font-face {
  font-family: 'Produkt LCG Web';
  src: url('assets/font/Produkt-Semibold-Cy-Gr-Web.woff2') format('woff2');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

.Produkt-Semibold-Cy-Gr-Web {
  font-family: 'Produkt LCG Web';
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

.lowercase{
  text-transform:lowercase;
}
.home-carousel .swiper-slide{
  overflow: hidden !important;
}
.mat-drawer-content {
  overflow: unset !important;
}
.mat-drawer-container[fullscreen] {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: unset !important;
}
.mat-drawer-inner-container{
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.mat-drawer-dialog-container{
  overflow-x: hidden !important;
}
div#mainDIV {
  overflow: unset !important;
}
/*Market Selector */
.marketselector-dialog-container .mat-dialog-container {
  padding: 0px !important;
}
.marketselector-dialog-container .mat-card {
  padding: 0px;
  overflow: hidden;
}
.marketselector-dialog-container .mat-card-header {
  display: flex;
  flex-direction: row;

  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}
.marketselector-dialog-container mat-card-header {
  margin-bottom: 0px;
}
.marketselector-dialog-container .mat-card-header-text {
  margin: 0px;
  width: 100%;
  text-align: center;
  background-color: #bebebe;
}
.marketselector-dialog-container div.mat-card-header-text {
  padding: 10px;
}
.marketselector-dialog-container .mat-card-title {
  margin-bottom: 0px !important;
  display: inline-block;
  font-size: 26px;
  font-weight: 400;
  color: #fff;
}
.marketselector-dialog-container .mat-form-field-flex {
  border-radius: 0px !important;
  padding: 0px 0.75em !important;
  box-shadow: 0 1px 4px 0 #000000 !important;
  background-color: #fff !important;
}
#ds_application2 {
  .mat-expansion-panel {
    margin: 24px 8px 8px 8px !important;

    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(250, 250, 250, 1);
  }
  h4 {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    opacity: 0.7 !important;
  }
  #verifystatus-Content {
    margin: 24px 8px 8px 8px !important;
  }
}
.avtar img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.f-s-16 {
  font-size: 16px;
}
.p-l-15 {
  padding-left: 15px;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-0 {
  margin: 0px !important;
}
.bgm-color2 {
  background-color: #007ec6 !important;
  color: #fff;
}
.text-uppercase {
  text-transform: uppercase;
}
.f-s-12 {
  font-size: 12px;
}
.d-flex {
  display: flex;
}

.exigo-summary-cart > mat-sidenav-container {
  width: 420px;
}

.marketselecter_find_popup {
  position: fixed !important;
  top: 0;
}
.mat-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 1rem;
  left: auto;
  position: fixed;
}

.has-error .help-block {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 3rem;
  left: 0px;
}
.captcha_answer {
  .has-error {
    position: relative;
    margin: 0;
    padding: 0;
    top: 4px;
    left: 0px;
  }
}
.captchaError {
  .has-error {
    position: relative;
    margin: 0;
    padding: 0;
    top: 0px;
    right: 0px;
    float: right;
  }
}
.help-block {
  color: red !important;
}
.mail_position {
  bottom: 8px;
  position: relative;
}
.v-align-middle{
  vertical-align: middle;
}
.v-align-bottom{
  vertical-align: bottom;
}
.main-nav__link {
  color: #262626;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  min-height: 48px;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.m-t--15 {
  margin-top: -15px;
}
.l-height-5{
  line-height: 5;
}

.checkbox .has-error{
  position: absolute;
  margin: 0;
  padding: 0;
  top: 1.85rem !important;
  left: 24px;
}
// .checkbox .has-error {
//   position: absolute;
//   margin: 0;
//   padding: 0;
//   top: 3rem;
//   left: 24px;
// }
html, body { height: 100%; }
body { margin: 0; font-family: 'Lato', sans-serif; }

/* payment page  starts ------------------------------------------------ */

.discount_sidebar .mat-form-field-infix {
  border-top: 0 !important;
}

@media (max-width: 480px) {
  .discount_sidebar .mat-form-field-infix {
    border-top: 0 !important;
    width: 182px !important;
  }
}

.discount_sidebar .mat-form-field-wrapper {
  border: 1px solid #bdbdbd;
}

.discount_sidebar  .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0 !important;
  padding: 0.5em !important;
}

.discount_sidebar .mat-form-field-appearance-fill .mat-form-field-infix {
 padding: 0.5em !important;
}

.discount_sidebar .mat-form-field-wrapper .mat-form-field-underline {
  opacity: 0;
}

.mat-form-field-underline {
  position: revert !important;
}

.mat-form-field .mat-form-field-wrapper {
  padding: 0 !important;
}

.mat-card,
.mat-accordion .mat-expansion-panel {
  border-radius: 0px !important;
}

.text-a-l {
  text-align: left;
}

.text-a-r {
  text-align: right;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-s-18 {
  font-size: 18px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.d-block {
  display: block !important;
}

.regular-shadow {
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1)!important;
}

mat-card {
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1)!important;
}

.b-box .mat-expansion-panel:not(.mat-expanded) .mat-expansion-indicator::after {
  content: "Edit" !important;
  border: none !important;
  transform: rotate(0deg) !important;
}




/* payment page  ends ------------------------------------------------ */

.text-white {
  color: #FFFFFF !important;
}
.text-light-white {
  color: #EEEEEE !important;
}
.m-0 {
  margin: 0 !important;
}

.badge {
  display: inline-block;
  padding: 5px;
  font-size: .8em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: #000;
  border-radius: 50%;
}

@media (max-width: 599px){
  .mb-xs-3 {
    margin-bottom: 1rem !important;
  }
}

.f-s-20 {
  font-size: 20px!important;
}

.f-s-25 {
  font-size: 25px !important;
}

.f-w-700 {
  font-weight: 700;
}

.select-market mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.select-market mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper .mat-form-field-infix {
  margin: 0px !important;
  margin: 4px 10px !important;
  border-top: 0px !important;
}

.theme-color {
  color: var(--theme-default) !important;
}

.text-dark {
  color: #2e2e2e !important;
}

.nav-text {
  padding: 39px 7px;
  color: #444;
  font-weight: bold;
  font-size: 14px;
  @media (max-width: 1199px) {
    font-size: 11px !important;
  }
  letter-spacing: 1px;
  font-family: 'Raleway',sans-serif;
}

.mat-expansion-panel.categories-panel .mat-expansion-panel-header .mat-expansion-indicator {
  margin-right: 80px !important;
}

.mat-expansion-panel.categories-panel {
  border: none !important;
}

mat-form-field.categories-select .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix  {
  padding: 0px !important;
  border-top: 0 !important;
}

mat-form-field.categories-select .mat-form-field-wrapper .mat-form-field-flex {
  padding: 15px 15px 7px 15px !important;;
}

mat-form-field.categories-select .mat-form-field-wrapper .mat-form-field-underline {
  opacity: 0 !important;
}

mat-form-field.categories-select  {
  border: 1px solid rgb(126, 126, 126);
}

.border {
  border: 1px solid #dee2e6!important;
}

// ----------------------------------------- */

.mat-tab-body{
  margin-top: 15px;
}

.product-detail-tabs mat-tab-header .mat-tab-label-container .mat-tab-list  .mat-tab-labels .mat-tab-label,
.rain-product-list-tabs mat-tab-header .mat-tab-label-container .mat-tab-list  .mat-tab-labels .mat-tab-label {
  padding: 15px !important;
  min-width: auto !important;
  width: auto !important;
}

.product-detail-tabs mat-tab-header,
.rain-product-list-tabs mat-tab-header {
  border-bottom: none !important;
}

.rain-product-list-tabs.mat-primary .mat-ink-bar {
  background-color: #333333 !important;
}

@media (max-width: 490px) {
  .product-detail-tabs mat-tab-header .mat-tab-label-container .mat-tab-list  .mat-tab-labels
   {
    // display: block !important;
  }
}

.mat-select-arrow {
  margin-top: 7px;
}

.thirdContainer mat-radio-group, .prod-detail-type mat-radio-group  {
  display: grid !important;
}

.mat-select-panel {
  min-width: 100% !important;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #161616 !important;
}

.mat-step-header{
  background-color: transparent !important;
}

mat-stepper.application-stepper .mat-horizontal-stepper-header-container mat-step-header.mat-step-header .mat-step-header-ripple {
  position: initial !important;
}

mat-stepper.application-stepper .mat-horizontal-stepper-header-container mat-step-header.mat-step-header .mat-step-text-label {
  color: var(--theme-default) !important;
  font-weight: 500 !important;
}

mat-stepper.application-stepper .mat-horizontal-stepper-header-container mat-step-header.mat-step-header .mat-step-icon {
  background-color: transparent;
  color: #c4c4c4;
  border: 2px solid #c4c4c4;
  border-radius: 99%;
}

mat-stepper.application-stepper .mat-horizontal-stepper-header-container mat-step-header.mat-step-header .mat-step-icon-selected {
  color: #fff !important;
}
mat-stepper.enroll-stepper .mat-horizontal-content-container{
  padding: 0px;
}

mat-stepper.application-stepper .mat-horizontal-stepper-header-container {
  border: 1px solid #e5e5e5 !important;
  border-top: none !important;
}

mat-stepper.rain-application .mat-horizontal-stepper-header-container mat-step-header.mat-step-header .mat-stepper-horizontal-line {
  width: 1px !important;
  min-width: 1px !important;
  max-width: 1px !important;
  border: 3px solid #161616 !important;
  border-radius: 100% !important;
  margin: 0 auto !important;
}

.select-business-kit mat-expansion-panel mat-expansion-panel-header {
  padding: 0 !important;
  padding-right: 10px !important;
}

.select-business-kit .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: transparent !important;
}

.pack-panel mat-radio-group mat-radio-button .mat-radio-label-content {
  color: #161616 !important;
  font-weight: 500;
  font-size: 15px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--theme-default) !important;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.no-border {
  border-bottom: none !important;
}
.f-s-15 {
  font-size: 15px !important;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  border-top: 0 !important;
  margin: 4px 10px;
}
.mat-dialog-container {
  border-radius: 0 !important;
  border: 1px solid #dddddd !important;
}
.mat-dialog-container > .confirm-dialog {
  border: none !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--theme-default) !important  ;
}
.cdk-overlay-container {
  z-index: 9999992;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container {
  padding: 0 !important;
  //padding-bottom: 35px !important;
}
.cdk-overlay-container .cdk-global-overlay-wrapper {
  align-items: start !important;
  padding-top: 35px !important;
}

span.mat-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
  font-size:medium
}

@media (max-width: 525px) {
  span.mat-content {
    font-size: 12px;
  }
}

.mat-form-field {
  margin: 10px 0 10px 0;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}
.mat-stroked-button {
  border-radius: 0 !important;
  border: 1px solid var(--theme-default) !important;
  color: var(--theme-default) !important;
}
.mat-stroked-button:hover {
 background-color: var(--theme-default) !important;
 color: #fff !important;
}
.mat-form-field-underline {
  opacity: 0 !important;
}
.mat-checkbox-layout {
  white-space: normal !important;
}
.mat-checkbox-checked.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--theme-default) !important;
}
.mat-typography, h1, h2, h3, h4, h5, h6 {
  font-family: Raleway !important;
}
p, span {
  font: 400 15px Lato;
  font-family: 'Lato', sans-serif !important;
}
.Mylogin {
  width: 320px !important;
  max-width: 320px !important;
}
.myHeaderLogin {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important;
  margin-top: 33px !important;
  border-radius: 0 !important;
  position: fixed;
  right:0;
  margin-right: 10px;
  // top: 112px;
}
.myHeaderLogin .mat-menu-content{
  width: 300px !important;
  // padding: 16px 0px 0px !important;
}
.summaryCartDropdown {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important;
  margin-top: 33px !important;
  border-radius: 0 !important;
  position: fixed;
  right: 0;
  margin-right: 10px;
}
.summaryCartDropdown .mat-menu-content{
  width: 300px !important;
  max-height: 501px !important;


}
.border-bottom {
  border-bottom: 1px solid #ddd !important;
}
.border-top {
  border-top: 1px solid #ddd !important;
}


// Apperence Outline Colors *********************************

.mat-form-field-appearance-outline, .mat-form-field-outline-start, .mat-form-field-outline-end {
  border-radius: 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ddd !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: transparent;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--theme-default) !important;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: var(--theme-default) !important;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: var(--theme-default) !important;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--theme-default) !important;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, .38)
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, .06)
}
.mat-form-field .mat-form-field-wrapper {
  background-color: #fff;
}

// Progress Bar Outline Colors *********************************

.mat-progress-bar-fill::after {
  background-color: var(--theme-default)
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: transparent
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #1cbedb4b
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #1cbedb
}


.lm_input.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 6px 0 3px 0 !important;
}
.lm_input.mat-form-field-appearance-outline .mat-form-field-flex {
  height: 45px !important;
  margin-top: -0.35em !important;
}

.img-fluid {
  max-width: 100% !important;
  height: auto !important;
}
.kit_price .mat-radio-button .mat-radio-outer-circle {
  background-color: #fff !important;
  border-color: #a0a0a0 !important;
  height: 45px !important;
  width: 45px !important;
}
.kit_price .mat-radio-button, .kit_price .mat-radio-button .mat-radio-inner-circle,
.kit_price .mat-radio-button .mat-radio-container  {
  height: 45px !important;
  width: 45px !important;
}

.kit_price .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background-color: var(--theme-default) !important;
}
.kit_price .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: #fff !important;
  height: 45px !important;
  width: 45px !important;
}
.config-info.mat-icon {
  color: var(--theme-default) !important;
}
.f-s-24 {
  font-size: 24px !important;
}
.f-s-40 {
  font-size: 40px !important;
}
.label, label {
  display: inline-flex !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  font-family: 'Raleway',sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 1px !important;
  color: #555 !important;
  // margin-bottom: 10px !important;
  cursor: pointer !important;
}
.bdr-none {
  border: none !important;
}
.cf_quantity_input.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0 12px !important;
  margin: 0 !important;
}
.config-progress-bar{
  width: 50%;
}
.config-progress-bar .mat-progress-bar {
  height: 1rem !important;
}
.config-progress-bar .prog_bar.mat-progress-bar .mat-progress-bar-fill::after{
  background-image: linear-gradient( 45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent) !important;
  background-size: 1rem 1rem !important;
}
.config-progress-bar .prog_bar.mat-progress-bar .mat-progress-bar-fill {
  min-width: 10% !important;
}
.prog_bar .mat-progress-bar-buffer {
  background-color: #EEEEEE !important
}

.search-prod-input.mat-form-field.mat-focused .mat-form-field-label{
  opacity:0 !important;
}
.search-prod-input.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(-716.999px) !important;
}
.summaryCartDropdown .mat-menu-content, .myHeaderLogin .mat-menu-content {
  border-top: 4px solid var(--theme-default) !important;
}
.mat-progress-bar {
  margin-top: 10px !important;
}
@media (max-width:690px) {
  .application-stepper .mat-horizontal-stepper-header {
    height: 45px !important;
  }
  .application-stepper .mat-stepper-horizontal-line {
    opacity: 0 !important;
  }
  .application-stepper .mat-horizontal-stepper-header-container {
    display: block !important;
  }
  .application-stepper .mat-horizontal-stepper-header-container:nth-child(4){
    margin-bottom: 20px !important;
  }
}
.mat-radio-label, .mat-checkbox-label {
  display: inline-flex !important;
}
.text-nonecase {
  text-transform: none !important;
}
span.mat-select-min-line {
  font: 400 16px 'Lato', sans-serif !important;
}
.product-detail-tabs .mat-tab-label-content {
  font: normal 18px 'spartan', sans-serif !important;
  color: #000;
  opacity: 1;
}
.rain-product-list-tabs .mat-tab-label-content {
  font: 600 16px Raleway !important;
  color: #000;
}
.pd_second_container .mat-tab-group.mat-primary .mat-ink-bar  {
  background-color: #000 !important;

}
.item-detail-container span, .item-detail-container p{
  font: 500 14px Raleway !important;
  line-height: 24px !important;
}
@media (max-width: 1320px) {
  .rel_btn span {
    font: 600 11px raleway !important;
  }
}
.rain-product-list .mat-option {
  background: 0 !important;
}
.top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0px;
}
.top-bar .mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: none !important;
}
.top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header span.mat-expansion-indicator {
  margin-right: 10px;
  transform: rotate(270deg) !important;
  border-width: 0 4px 4px 0;
  padding: 5px;
}
.top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header span.mat-expansion-indicator:after {
  border-width: 0 4px 4px 0;
  padding: 5px;
}
.top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded span.mat-expansion-indicator:after {
  transform: rotate(135deg) !important;
  margin-left: 8px;
  border-width: 0 4px 4px 0;
  padding: 5px;
}
.top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  width: 172px !important;
}
.top-bar .mat-accordion .mat-expansion-panel{
  border: none !important;
}
.top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body{
  margin-top: -57px;
  width: 100%;
  margin-left: 318px;
}
@media (max-width: 480px) {
  .top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body{
    margin-top: -7px;
    padding: 0 8px 16px;
    margin-left: 0;
  }
  .top-bar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding: 0 15px;
  }
}

.product-right .product-videos swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle {
  margin: 3px !important;
}
.product-right .product-videos {
  .swiper-button-prev, .swiper-button-next {
    display: none !important;
  }
}
.product-right .product-videos swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet-active {
  background-color: #333333 !important;
}
.product-right .product-videos swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet {
  padding: 0.3rem;
  display: inline-block !important;
  border: 1px solid #000 !important;
  background: #fff;
  opacity: 1;
}
.product-right .product-videos swiper > .swiper.s-wrapper .swiper-pagination {
  position: absolute !important;
  // margin-bottom: -50px !important;
}
.product-videos .swiper-slide div {
  place-content: flex-start !important;
  align-items: center !important;
}
.mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--theme-default) !important
}
.mat-select-panel-wrap, .mat-select-panel {
  border-radius: 0 !important;
}
.mat-select-panel {
  border: 1px solid #ddd !important;
  box-shadow: 4px 4px 14px 3px #DDDDDD !important;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  padding: 0px !important;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
  padding: 20px 50px;
  border-bottom: 1px solid #e3e3e3;
}

@media (max-width: 480px) {
  .mat-stepper-label-position-bottom {
    .mat-horizontal-stepper-header {
      max-width: 100% !important;
      margin-bottom: 0.5rem !important;
    }
    .mat-stepper-horizontal-line {
      display: none !important;
    }
  }
}
.main-container {
  width: 100%;
  margin:0 auto;
  max-width: 1300px;
}

.center-subscribe-dialog {
  margin-top: 160px !important;
}

.center-reward-dialog {
  margin-top: 160px !important;
}
.center-video-dialog {
  top: 10% !important;
}
.center-quickview-dialog {
  margin-top: 100px !important;
  .mat-dialog-container {
    overflow: visible;
  }
}

.confirm-reward-dialog {
  width: 100%;
  max-width: 720px;
  height: 400px;
}
.confirm-video-dialog {
  // width: 100%;
  // max-width: 720px;
  // height: 400px;
  position: relative !important;
}

/*Reward Video */
.confirm-video-dialog .mat-dialog-container {
  position: unset !important;
}

.confirm-reward-dialog .mat-dialog-container {
  overflow: auto !important;
  border: none !important;
}
.confirm-reward-dialg .mat-dialog-container {
  overflow: auto !important;
  border: none !important;
}
@media (max-width: 480px) {
  .center-subscribe-dialog {
    margin-top: 90px !important;
  }
  .center-reward-dialog {
    margin-top: 90px !important;
    border: none;
  }
  .center-video-dialog {
    margin-top: 90px !important;
    border: none;
  }
  .center-quickview-dialog {
    margin-top: 60px !important;
  }
  .center-quickview-dialog .mat-dialog-content {
    max-height: 100vh !important;
  }

  .confirm-reward-dialog {
   margin: 0 10px;
  }
}
.config_incomplete .mat-progress-bar-fill::after {
  background-color:#FC4F48;
  background-image:none;
}
.config_complete .mat-progress-bar-fill::after {
  background-color:#56B77E;
  background-image:none;
}
.config-progress-bar .prog_bar.mat-progress-bar .mat-progress-bar-fill::after{
  background-image: none !important;
}
.config-progress-bar .prog_bar .mat-progress-bar-buffer{
  background-color:#fff !important;
}
.config-progress-bar .mat-progress-bar{
  border:2px solid #000;
}
//Feed Slider
.product-feed swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle {
  margin: 3px !important;
}
.product-feed {
  .swiper-button-prev, .swiper-button-next {
    display: none !important;
  }
}
 .product-feed swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet-active {
  background-color: #333333 !important;
}
 .product-feed swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet {
  padding: 0.3rem;
  display: inline-block !important;
  border: 1px solid #000 !important;
  background: #fff;
  opacity: 1;
}
 .product-feed swiper > .swiper.s-wrapper .swiper-pagination {
  position: absolute !important;
}
.product-feed .swiper-container-horizontal>.swiper-pagination-bullets,.product-feed .swiper-pagination-custom,.product-feed .swiper-pagination-fraction {
  bottom: 0px;
  left: 0;
  width: 100%;
}
.product-right .swiper-container-horizontal>.swiper-pagination-bullets,.product-feed .swiper-pagination-custom,.product-feed .swiper-pagination-fraction {
  bottom: 0px;
  left: 0;
  width: 100%;
}
.center-quickview-dialog .mat-dialog-content {
  display: block;
  margin: -1px -24px;
  padding: 0 19px 0 23px;
  max-height: 500px;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.cardiframe {
.mat-form-field-infix{
  padding: 6px !important;
}
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.tippy-box {
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
}
.tippy-content{
  padding: 0px;
}
.enrollmentwebalias_section {
  .mat-form-field-prefix {
    top: 0px !important;
  }
  .mat-form-field-infix {
    position: initial;
  }

  .mat-form-field-label-wrapper {
    top: 0;
    left: 15px;
  }
  .has-error {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 4rem !important;
    left: 0px;
  }
}
.mat-drawer-container {
  position: relative;
  z-index: unset !important;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
  background-color: rgb(237, 236, 236,1);
}
.more_word {
  position: relative;
  bottom: 0;
  cursor: pointer;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ContactSupport-Dialog_Popup {
  padding: 20px;
  max-height: 90vh;
}

.SkipToHome-Dialog_Popup {
  margin-top: 20px;
  padding: 20px;
  position: absolute !important;
  top: 25% !important;
  .text-content-dialog {
    font: 700 22px 'Raleway';
    color: #f00;
  }
}